/* eslint-disable import/no-extraneous-dependencies */
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';
import ExifReader from 'exifreader';

/**
 * Check the file type using ExifTool.
 *
 * @param {File} file - The file to check.
 * @returns {Promise<string>} A promise that resolves to the file type.
 */
const getFileType = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const tags = ExifReader.load(arrayBuffer);
  return tags.FileType ? tags.FileType.description : 'unknown';
};

/**
 * Compresses the given file, converting HEIC format to JPEG if necessary.
 *
 * @param {File} file - The file to be compressed.
 * @returns {Promise<File>} A Promise that resolves to the compressed File.
 */
export const compressFile = async (file) => {
  // Get the file extension
  const fileExtension = await getFileType(file);

  // Check if the file is in HEIC format
  if (fileExtension === 'HEIC') {
    try {
      return new Promise((resolve) => {
        // Read the file as a data URL
        const reader = new FileReader();
        reader.readAsDataURL(file);

        // Process the data URL
        reader.onload = async () => {
          const result = reader.result;
          const res = await fetch(result);
          const blob = await res.blob();
          let resultBlob = blob;
          try {
            // Convert HEIC to JPEG using heic2any library
            resultBlob = await heic2any({
              blob,
              toType: 'image/jpeg'
            });
          } catch (error) {
            // If conversion fails, resolve with the original file
            return resolve(file);
          }

          // Image compression options
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1680,
            useWebWorker: true,
            initialQuality: 0.5
          };

          // Compress the resulting blob using imageCompression library
          const compressedBlob = await imageCompression(resultBlob, options);

          // Create a new File from the compressed blob
          const newFile = new File([compressedBlob], file.name.split('.')[0], {
            type: compressedBlob.type
          });

          // Set the uid property on the new file
          newFile.uid = file.uid;

          // Resolve with the compressed File
          return resolve(newFile);
        };
      });
    } catch (error) {
      // If any error occurs, resolve with the original file
      return file;
    }
  } else {
    try {
      // If the file is not in HEIC format, perform regular compression
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1680,
        useWebWorker: true,
        initialQuality: 0.5
      };

      // Compress the file using imageCompression library
      const compressedBlob = await imageCompression(file, options);

      // Create a new File from the compressed blob
      const newFile = new File([compressedBlob], file.name, {
        type: file.type
      });

      // Set the uid property on the new file
      newFile.uid = file.uid;

      // Resolve with the compressed File
      return newFile;
    } catch (error) {
      return file;
    }
  }
};
