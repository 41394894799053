import { useState, useEffect } from 'react';
import { Card, Col, Row, Avatar, Button, Divider, Form } from 'antd';
import {
  ExportOutlined,
  CheckOutlined,
  CloseOutlined,
  MailOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { pensionersInfo } from '../listContent/pensionersInfo';
import { DescriptionList } from '../../../../../components';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { CancelPensionerModal } from '../modals/CancelPensionerModal';
import { confirmPensioner } from '../../utils/confirmPensioner';
import { getPensionerReservation } from '../../utils/getPensionerReservation';
import { MailingModal } from '../../../../../components/Mailing/MailingModal';
import { LoadingModal } from '../../../../../components/LoadingModal/LoadingModal';

/**
 * Renders a card component for displaying details of a pensioner.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.pensioner - The pensioner object.
 * @param {Function} props.t - The translation function.
 * @param {Object} props.supervision - The supervision object.
 * @param {Function} props.refreshData - The function to refresh data.
 * @param {Function} props.setRefreshData - The function to set the refresh data flag.
 * @param {number} props.pensionerCount - The count of pensioners.
 * @param {Function} props.setOpenModal - The function to set the open modal flag.
 * @param {boolean} props.openModal - The flag indicating whether the modal is open.
 * @returns {JSX.Element} The rendered PensionerCard component.
 */
export const PensionerCard = ({
  pensioner,
  t,
  supervision,
  refreshData,
  setRefreshData,
  pensionerCount,
  setOpenModal,
  openModal
}) => {
  const { dispatchAPI, token } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm(null);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [reservation, setReservation] = useState({});
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [templateType, setTemplateType] = useState('SEND_MAIL');
  const [documentType, setDocumentType] = useState(null);

  const { principalInfo, contactInfo, secondaryProfileInfo } = pensionersInfo(
    pensioner,
    t
  );
  const columnSpan =
    pensioner && Object.keys(pensioner?.secondary_profile).length ? 6 : 12;

  useEffect(() => {
    (async () => {
      await getPensionerReservation({
        dispatchAPI,
        pensioner,
        supervision,
        setReservation,
        message
      });
    })();
  }, []);

  const principalPhoto = pensioner.documents?.find(
    (photo) => photo.type === 'identification_photo'
  );

  const secondaryPhoto = pensioner?.secondary_profile?.documents?.find(
    (photo) => photo.type === 'secondary_profile_identification_photo'
  );

  return (
    <>
      <Card
        title={t('supervisions.show.pensioner.title', {
          reference: pensioner.reference
        })}
        extra={
          <>
            <Button
              type="link"
              style={{ fontSize: 18 }}
              onClick={() => {
                setIsEmailModalOpen(true);
                setTemplateType('SEND_MAIL');
                setDocumentType(null);
              }}
            >
              <MailOutlined />
            </Button>
            {supervision.status === 'WAITING_BOOKING' ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={() =>
                    confirmPensioner({
                      dispatchAPI,
                      supervision,
                      setRefreshData,
                      message,
                      refreshData,
                      t,
                      setOpenModal
                    })
                  }
                  style={{ padding: 0 }}
                >
                  <CheckOutlined
                    style={{ fontSize: 18, color: 'var(--addColor)' }}
                  />
                </Button>
              </>
            ) : null}

            {![
              'IN_PROGRESS',
              'FINISHED',
              'CANCEL',
              'INTERRUPT',
              'ARCHIVED'
            ].includes(supervision.status) ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={() => setIsCancelModalOpen(true)}
                  style={{ padding: 0 }}
                >
                  <CloseOutlined
                    style={{ fontSize: 18, color: 'var(--errorColor)' }}
                  />
                </Button>
              </>
            ) : null}

            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/pensioners/show/${pensioner._id}`
              }}
            >
              <ExportOutlined
                style={{ fontSize: 18, color: 'var(--secondaryColor)' }}
              />
            </Link>
          </>
        }
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} xxl={10}>
            <DescriptionList data={contactInfo} translate />
          </Col>
          <Col span={columnSpan}>
            <Row style={{ gap: 16 }}>
              {principalPhoto ? (
                <Avatar
                  src={`${process.env.REACT_APP_API_URL}/get-file-url/${principalPhoto?._id}/${token}`}
                  size={100}
                  alt=""
                />
              ) : (
                <Avatar size={100} />
              )}

              <DescriptionList data={principalInfo} translate />
            </Row>
          </Col>
          {pensioner && Object.keys(pensioner?.secondary_profile).length ? (
            <Col span={columnSpan}>
              <Row style={{ gap: 16 }}>
                {secondaryPhoto ? (
                  <Avatar
                    src={`${process.env.REACT_APP_API_URL}/get-file-url/${secondaryPhoto?._id}/${token}`}
                    size={100}
                    alt=""
                  />
                ) : (
                  <Avatar size={100} />
                )}
                <DescriptionList data={secondaryProfileInfo} translate />
              </Row>
            </Col>
          ) : null}
        </Row>
      </Card>
      <CancelPensionerModal
        isCancelModalOpen={isCancelModalOpen}
        setIsCancelModalOpen={setIsCancelModalOpen}
        pensionerId={pensioner._id}
        supervision={supervision}
        reservationId={reservation?._id}
        t={t}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        pensionerCount={pensionerCount}
      />
      {isEmailModalOpen && (
        <MailingModal
          isModalOpen={isEmailModalOpen}
          setIsModalOpen={setIsEmailModalOpen}
          recipients={[{ ...pensioner, customer_type: 'OWNER' }]}
          recipient={pensioner?.user?._id}
          customFormInstance={form}
          templateType={templateType}
          documentType={documentType}
          collection="owners"
        />
      )}
      <LoadingModal
        openModal={openModal}
        loadingText="supervisions.show.modal.title.supervision_booking"
      />
    </>
  );
};

PensionerCard.propTypes = {
  pensioner: PropTypes.shape({
    _id: PropTypes.string,
    reference: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string
      })
    ),
    secondary_profile: PropTypes.shape({
      birth_date: PropTypes.string,
      civility: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      documents: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    photo: PropTypes.string,
    user: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  t: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    pensioners: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  refreshData: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  pensionerCount: PropTypes.number.isRequired,
  setOpenModal: PropTypes.func,
  openModal: PropTypes.bool
};

PensionerCard.defaultProps = {
  setOpenModal: () => {},
  openModal: false
};
