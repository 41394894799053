import { Flex, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * ExtraFilters component renders a date picker for filtering data by date.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setDateFilter - Function to set the date filter.
 * @returns {JSX.Element} The ExtraFilters component.
 */
export const ExtraFilters = ({ setDateFilter }) => {
  const { t } = useTranslation();

  return (
    <Flex gap="middle" align="center">
      <DatePicker
        placeholder={t('supervisions.place_holders.start_date')}
        style={{ width: 200 }}
        format="DD/MM/YYYY"
        onChange={(e) => setDateFilter(e)}
      />
    </Flex>
  );
};

ExtraFilters.propTypes = {
  setDateFilter: PropTypes.func
};

ExtraFilters.defaultProps = {
  setDateFilter: () => {}
};
