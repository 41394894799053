import PropTypes from 'prop-types';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListInvoices } from '../dataTables/ListInvoices';

/**
 * Renders a card component that displays a list of invoices for a supervision.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {function} props.setCustomerInvoices - A function to set the customer invoices.
 * @param {boolean} props.isLoading - A flag indicating if the component is in a loading state.
 * @returns {JSX.Element} The rendered component.
 */
export const InvoicesCard = ({
  supervisionId,
  setCustomerInvoices,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card title={t('supervisions.show.invoices.title')}>
        <ListInvoices
          supervisionId={supervisionId}
          setCustomerInvoices={setCustomerInvoices}
        />
      </Card>
    </Spin>
  );
};

InvoicesCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  supervisionId: PropTypes.string.isRequired,
  setCustomerInvoices: PropTypes.func.isRequired
};
