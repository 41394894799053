import React, { useState } from 'react';
import { Select, DatePicker, InputNumber } from 'antd';

const { Option } = Select;

/**
 * Hook pour obtenir les champs nécessaires pour marquer une facture comme payée.
 * @function
 * @param {Object} props - Les propriétés du hook.
 * @param {Object} props.enums - Les énumérations pour les méthodes de paiement.
 * @param {Function} props.t - Fonction pour traduire les textes.
 * @returns {Object} Les champs de la facture payée.
 */
export const usePaidInvoiceFields = ({ enums, t }) => {
  const [paymentMethod, setPaymentMethod] = useState('');

  const paidInvoiceFields = [
    {
      name: ['payment_method'],
      label: 'payment_method',
      rules: [{ required: true }],
      input: (
        <Select onChange={(e) => setPaymentMethod(e)}>
          {enums.payment_method.map((item) => (
            <Option value={item} key={item}>
              {t(
                `customerinvoices.tags.${
                  item === 'CHEQUE' ? `${item}_METHOD` : item
                }`
              )}
            </Option>
          ))}
        </Select>
      )
    },
    ...(paymentMethod === 'CHEQUE' ? [{ name: ['cheque_number'] }] : []),
    {
      name: ['payment_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['payment_amount'],
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" disabled={paymentMethod === 'FREE'} />,
      valuePropName: paymentMethod === 'FREE' ? 0 : 'value'
    }
  ];

  return { paidInvoiceFields };
};
