import { Supervision } from '../../../models/Supervision';

/**
 * Custom hook to provide action buttons for supervision management.
 * @hook
 *
 * @param {Object} params - Parameters for the hook.
 * @param {string} params.supervisionId - The ID of the supervision.
 * @param {Function} params.setRefreshData - Function to trigger data refresh.
 * @returns {Object} - An object containing action handlers.
 * @returns {Function} return.handlePatchStatus - Function to patch the status of the supervision.
 * @returns {Function} return.handleDelete - Function to delete (archive) the supervision.
 */
export const useActionsButtons = ({ supervisionId, setRefreshData }) => {
  /**
   * Asynchronously patches the status of a supervision to 'WAITING' and triggers a refresh of the data.
   *
   * @async
   * @function handlePatchStatus
   * @returns {Promise<void>} A promise that resolves when the status has been patched and the data refresh has been triggered.
   */
  const handlePatchStatus = async () => {
    await Supervision.patch(`${supervisionId}/status`, {
      status: 'WAITING'
    });
    setRefreshData((prevState) => !prevState);
  };

  /**
   * Handles the deletion of a supervision by setting its status to 'ARCHIVED' and marking it as archived.
   * After the deletion, it triggers a refresh of the data.
   *
   * @async
   * @function handleDelete
   * @returns {Promise<void>} A promise that resolves when the deletion and data refresh are complete.
   */
  const handleDelete = async () => {
    await Supervision.delete(supervisionId, {
      status: 'ARCHIVED',
      is_archived: true
    });
    setRefreshData((prevState) => !prevState);
  };

  return {
    handlePatchStatus,
    handleDelete
  };
};
