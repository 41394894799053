import dayjs from 'dayjs';

export const bothListContent = ({
  profile: {
    first_name,
    last_name,
    phone_number,
    cell_phone_number,
    email,
    address,
    first_profession,
    second_profession,
    civility,
    birth_date,
    profession,
    principal_language,
    reference
  },
  t,
  user: { role }
}) => [
  ...(role === 'users:PENSIONER' && reference
    ? [
        {
          label: t('profile.personal_info.reference'),
          content: reference || '-'
        }
      ]
    : []),
  {
    label: t('profile.personal_info.civility'),
    content: civility ? t(`users.tags.${civility}`) : '-'
  },
  {
    label: t('profile.personal_info.last_name'),
    content: last_name || '-'
  },
  {
    label: t('profile.personal_info.first_name'),
    content: first_name || '-'
  },
  ...(role && role === 'users:PENSIONER'
    ? [
        {
          label: t('profile.personal_info.birth_date'),
          content: birth_date ? dayjs(birth_date).format('DD/MM/YYYY') : '-'
        },
        {
          label: t('profile.personal_info.language'),
          content: principal_language || '-'
        }
      ]
    : []),
  ...(role === 'users:OWNER'
    ? [
        {
          label: t('profile.personal_info.phone_number'),
          content: phone_number ? phone_number?.number : '-'
        }
      ]
    : []),
  {
    label: t('profile.personal_info.cell_phone_number'),
    content: cell_phone_number ? cell_phone_number?.number : '-'
  },
  ...(role === 'users:OWNER'
    ? [
        {
          label: t('profile.personal_info.email'),
          content: email || '-'
        }
      ]
    : []),
  ...(role === 'users:OWNER'
    ? [
        {
          label: t('profile.personal_info.first_profession'),
          content: first_profession || '-'
        },
        {
          label: t('profile.personal_info.second_profession'),
          content: second_profession || '-'
        },
        {
          label: t('profile.form.address'),
          content:
            `${address?.number || ''}
                  ${address?.street || ''}
                  ${address?.additional || ''}
                  ${address?.postal_code || ''} ${address?.city || ''} ${
              address?.country || ''
            }` || '-'
        }
      ]
    : [
        {
          label: t('profile.personal_info.profession'),
          content: profession || '-'
        }
      ])
];
