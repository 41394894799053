import { Typography, Button, Row, Col, Modal, Flex } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { reserveSupervision } from './utils/reserveSupervision';

const { Text } = Typography;

export const ConfirmReservationModal = ({
  setIsReservationModalOpen,
  isReservationModalOpen,
  supervisionId,
  dispatchAPI,
  message,
  user,
  navigate,
  anounceId
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('view-ads.modals.titles.reservation')}
      open={isReservationModalOpen}
      onOk={() => setIsReservationModalOpen(false)}
      onCancel={() => setIsReservationModalOpen(false)}
      footer={
        <Row>
          <Col>
            <Button onClick={() => setIsReservationModalOpen(false)}>
              {t('buttons.cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() =>
                reserveSupervision({
                  supervisionId,
                  dispatchAPI,
                  message,
                  user,
                  navigate,
                  t,
                  setIsReservationModalOpen,
                  anounceId
                })
              }
            >
              {t('buttons.confirm')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Flex vertical>
        <Text>{t('view-ads.modals.contents.reservation')}</Text>
        <Text>{t('view-ads.modals.contents.email_message')}</Text>
      </Flex>
    </Modal>
  );
};

ConfirmReservationModal.propTypes = {
  setIsReservationModalOpen: PropTypes.func.isRequired,
  isReservationModalOpen: PropTypes.bool.isRequired,
  supervisionId: PropTypes.string.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  navigate: PropTypes.func,
  anounceId: PropTypes.string
};

ConfirmReservationModal.defaultProps = {
  navigate: () => {},
  anounceId: ''
};
