import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGenerateDocumentsOld } from '../../../../utils/filesManagement/useGenerateDocumentOld';
import { useActionsButtons } from '../../utils/useActionsButtons';

/**
 * InterruptButtons component renders a set of buttons for duplicating supervision,
 * generating a synthesis document, and archiving a supervision.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {Function} props.setRefreshData - Function to refresh data after an action.
 * @returns {JSX.Element} The rendered component.
 */
export const InterruptButtons = ({ supervisionId, setRefreshData }) => {
  const { t } = useTranslation();
  const { generateDocumentsOld } = useGenerateDocumentsOld();
  const { handleDelete } = useActionsButtons({ supervisionId, setRefreshData });

  return (
    <>
      <Button type="primary">
        <PlusOutlined />
        {`${t('supervisions.show.buttons.duplicate_supervision')} `}
      </Button>
      <Button
        type="primary"
        onClick={() =>
          generateDocumentsOld('SUPERVISION_SUMMARY', supervisionId, undefined)
        }
      >
        <UploadOutlined />
        {`${t('supervisions.show.buttons.generate_synthesis')} `}
      </Button>
      <Popconfirm
        title={t('datatable.column.action.archive.title')}
        okText={t('datatable.column.action.archive.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.archive.cancel')}
        onConfirm={handleDelete}
        icon={<WarningOutlined />}
      >
        <Button type="link" danger>
          {`${t('buttons.archive')} `}
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </>
  );
};

InterruptButtons.propTypes = {
  setRefreshData: PropTypes.func.isRequired,
  supervisionId: PropTypes.string.isRequired
};
