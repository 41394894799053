import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { message as Message } from 'antd';
import { useStateWithLocalStorage } from '../utils';
import { useErrorMessage } from '../utils/errorMessage';
import { routes as userRoutes } from '../utils/constants/userRoutes';
import { routes as adminRoutes } from '../utils/constants/adminRoutes';
import { BaseModel } from '../models/BaseModel';

export const AuthContext = createContext({ isValid: false });
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN'
  });
  const [token, setToken] = useStateWithLocalStorage('token');
  const [refreshToken, setRefreshToken] =
    useStateWithLocalStorage('refresh_token');
  const [remember, setRememberMe] = useStateWithLocalStorage(
    'remember_me',
    !!refreshToken
  );
  const [isValid, setIsValid] = useState(!!refreshToken || !!token);
  const [pendingTasksTray, setPendingTasksTray] = useStateWithLocalStorage(
    'pendingTasksTray',
    []
  );

  const { _id, email: userEmail } = user || {};
  const [profilesArr, setProfilesArr] = useState([]);
  const [pensionerInfo, setPensionerInfo] = useState({});
  const [ownerInfo, setOwnerInfo] = useState({});
  const { message } = useErrorMessage();

  const setSession = (accessToken, logout) => {
    const findRouteKeyByPath = (path, routes) => {
      const routeKeys = Object.keys(routes);
      const foundKey = routeKeys.find((key) => routes[key] === `/${path}`);
      return foundKey || null;
    };

    const prevUrl = window.location.href.split('/').pop();
    const routeKey = findRouteKeyByPath(prevUrl, {
      ...userRoutes,
      ...adminRoutes
    });

    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setRefreshToken(null);
      setUser(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
      if (!logout && routeKey) {
        Message.warning(t('login.expiredSession'));
      }
    }
  };

  const loginAPI = async (email, password, rememberMe) => {
    const result = await axiosInstance.get('/login', {
      auth: {
        username: email,
        password
      }
    });
    setUser(result.data.user);
    setRememberMe(rememberMe);
    if (rememberMe) setRefreshToken(result.data.refresh_token);
    setSession(result.data.token, false);
    return result;
  };

  const registerAPI = (values) => axiosInstance.post('/register', values);

  const logout = () => {
    setSession(null, true);
    setUser(null);
  };

  const isTokenExpired = () => {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (Number(decodedToken.exp) < Number(currentTime)) {
      return true;
    }
    return false;
  };

  const isTokenValid = async () => {
    if ((!token || isTokenExpired()) && !refreshToken) {
      setSession(null, false);
      return false;
    }
    try {
      if (remember && refreshToken && (!token || isTokenExpired())) {
        const { data } = await axiosInstance.post('/token/refresh', {
          refresh_token: refreshToken
        });
        setToken(data.newToken);
        return { newToken: data.newToken };
      }
    } catch (e) {
      message.warning(t('login.expiredSession'));
      setSession(null, false);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      await isTokenValid();
    })();
  }, []);

  const getUserProfiles = async () => {
    try {
      const { newToken } = await isTokenValid();

      const { data } = await axiosInstance({
        url: `users/${_id}/profiles?email=${userEmail}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${newToken || token}`
        }
      });

      if (data[0]) {
        const { data: pensionerData } = await axiosInstance({
          url: `pensioners/${data[0]}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${newToken || token}`
          }
        });
        if (pensionerData) {
          setPensionerInfo(pensionerData);
        }
      }

      if (data[1] || data[0]) {
        const { data: ownerData } = await axiosInstance({
          url: `owners/${data[1] ?? data[0]}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${newToken || token}`
          }
        });
        if (ownerData) {
          setOwnerInfo(ownerData);
        }
      }

      setProfilesArr(data);
    } catch (e) {
      message(e);
    }
  };

  const fetchAPI = async (
    url,
    method = 'GET',
    body = undefined,
    responseType = 'json',
    cancelToken = undefined,
    params = undefined
  ) => {
    const { newToken } = await isTokenValid();
    if (
      ['POST', 'PATCH', 'DELETE'].includes(method) &&
      !window.navigator.onLine
    ) {
      setPendingTasksTray([
        ...(pendingTasksTray || []),
        ...[{ url, method, body }]
      ]);
      return new Response();
    }
    const result = await axiosInstance({
      url,
      method,
      responseType,
      cancelToken,
      data: body,
      params,
      headers: {
        Authorization: `Bearer ${newToken || token}`
      }
    });
    return result;
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken,
          options.params
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
        return fetchAPI(options.url, type, options.body, options.responseType);
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        throw new Error('Unknown dispatchAPI type!');
    }
  };
  BaseModel.dispatchAPI = dispatchAPI;
  BaseModel.message = message;

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        profilesArr,
        token,
        isValid,
        dispatchAPI,
        remember,
        pendingTasksTray,
        setPendingTasksTray,
        getUserProfiles,
        pensionerInfo,
        ownerInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
