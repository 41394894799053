import PropTypes from 'prop-types';
import { RequestButtons } from './components/buttons/RequestButtons';
import { WaitingButtons } from './components/buttons/WaitingButtons';
import { PublishedButtons } from './components/buttons/PublishedButtons';
import { WaitingBookingButtons } from './components/buttons/WaitingBookingButtons';
import { BookedButtons } from './components/buttons/BookedButtons';
import { InProgressButtons } from './components/buttons/InProgressButtons';
import { FinishedButtons } from './components/buttons/FinishedButtons';
import { InterruptButtons } from './components/buttons/InterruptButtons';
import { CancelButtons } from './components/buttons/CancelButtons';

/**
 * ActionsButtons component renders different sets of action buttons based on the supervision status.
 * @component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {string} props.status - The current status of the supervision.
 * @param {Function} props.setRefreshData - Function to refresh data.
 * @param {Function} props.setAnounceModalVisible - Function to set the visibility of the announce modal.
 * @param {Function} props.setCancelSupervisionModalVisible - Function to set the visibility of the cancel supervision modal.
 * @param {Function} props.setIsEmailModalOpen - Function to set the visibility of the email modal.
 * @param {Function} props.setInterruptSupervisionModalVisible - Function to set the visibility of the interrupt supervision modal.
 * @returns {JSX.Element|null} The appropriate set of action buttons based on the status, or null if the status is not recognized.
 */
export const ActionsButtons = ({
  supervisionId,
  status,
  setRefreshData,
  setAnounceModalVisible,
  setCancelSupervisionModalVisible,
  setIsEmailModalOpen,
  setInterruptSupervisionModalVisible
}) => {
  const props = {
    supervisionId,
    setRefreshData
  };

  const actionsButtons = {
    REQUEST: <RequestButtons {...props} />,
    WAITING: (
      <WaitingButtons
        {...props}
        setAnounceModalVisible={setAnounceModalVisible}
      />
    ),
    PUBLISHED: (
      <PublishedButtons
        {...props}
        setCancelSupervisionModalVisible={setCancelSupervisionModalVisible}
      />
    ),
    WAITING_BOOKING: (
      <WaitingBookingButtons
        {...props}
        setCancelSupervisionModalVisible={setCancelSupervisionModalVisible}
        setIsEmailModalOpen={setIsEmailModalOpen}
      />
    ),
    BOOKED: (
      <BookedButtons
        {...props}
        setIsEmailModalOpen={setIsEmailModalOpen}
        setCancelSupervisionModalVisible={setCancelSupervisionModalVisible}
      />
    ),
    IN_PROGRESS: (
      <InProgressButtons
        {...props}
        setIsEmailModalOpen={setIsEmailModalOpen}
        setInterruptSupervisionModalVisible={
          setInterruptSupervisionModalVisible
        }
      />
    ),
    FINISHED: (
      <FinishedButtons {...props} setIsEmailModalOpen={setIsEmailModalOpen} />
    ),
    INTERRUPT: <InterruptButtons {...props} />,
    CANCEL: <CancelButtons {...props} />
  };

  return actionsButtons[status] || null;
};

ActionsButtons.propTypes = {
  status: PropTypes.string,
  setRefreshData: PropTypes.func.isRequired,
  setAnounceModalVisible: PropTypes.func.isRequired,
  setCancelSupervisionModalVisible: PropTypes.func.isRequired,
  setIsEmailModalOpen: PropTypes.func.isRequired,
  supervisionId: PropTypes.string.isRequired,
  setInterruptSupervisionModalVisible: PropTypes.func.isRequired
};

ActionsButtons.defaultProps = {
  status: ''
};
