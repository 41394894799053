import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useGenerateDocumentsOld } from '../../../../utils/filesManagement/useGenerateDocumentOld';

/**
 * PublishedButtons component renders two buttons for generating a supervision summary document
 * and for canceling a supervision.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setCancelSupervisionModalVisible - Function to set the visibility of the cancel supervision modal.
 * @param {string} props.supervisionId - The ID of the supervision.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const PublishedButtons = ({
  setCancelSupervisionModalVisible,
  supervisionId
}) => {
  const { t } = useTranslation();
  const { generateDocumentsOld } = useGenerateDocumentsOld();

  return (
    <>
      <Button
        type="primary"
        onClick={() =>
          generateDocumentsOld('SUPERVISION_SUMMARY', supervisionId, undefined)
        }
      >
        <UploadOutlined />
        {`${t('supervisions.show.buttons.generate_synthesis')} `}
      </Button>
      <Button
        type="primary"
        style={{ backgroundColor: 'red' }}
        onClick={() => setCancelSupervisionModalVisible(true)}
      >
        <CloseOutlined />
        {`${t('supervisions.show.buttons.cancel_supervision')} `}
      </Button>
    </>
  );
};

PublishedButtons.propTypes = {
  setCancelSupervisionModalVisible: PropTypes.func.isRequired,
  supervisionId: PropTypes.string.isRequired
};
