import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ListResource } from '../../../../../components/ListResource/ListResource';
import { ReviewDrawer } from '../../../../../components/Review/ReviewDrawer';
import { setRatingShowInfo } from '../../utils/setRatingShowInfo';
import { useColumnsRatings } from './columns/columnsRatings';

/**
 * ListRatings component renders a list of ratings associated with a supervision.
 * It manages the state and actions related to displaying and editing reviews.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.supervision - The supervision object containing details about the supervision.
 * @returns {JSX.Element} The rendered ListRatings component.
 *
 * @example
 * <ListRatings supervision={supervision} />
 *
 * @component
 */
export const ListRatings = ({ supervision }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [showReviewDrawer, setShowReviewDrawer] = useState(false);
  const [editReviewDrawer, setEditReviewDrawer] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [questionnaireType, setQuestionnaireType] = useState('');

  const { columns } = useColumnsRatings({
    setEditReviewDrawer,
    setShowReviewDrawer,
    setReviewData,
    setOpen
  });

  useEffect(() => {
    if (forceRefresh) setForceRefresh(false);
  }, [forceRefresh]);

  useEffect(() => {
    if (Object.keys(reviewData).length) {
      setRatingShowInfo({
        reviewData,
        form,
        setQuestionnaireType
      });
    }
  }, [reviewData]);

  const setExtraQuery = () => {
    let extraQuery = '';
    if (supervision._id) {
      extraQuery = `supervision=${supervision._id}&`;
    }
    return extraQuery;
  };

  useEffect(() => {
    setExtraQuery();
  }, [supervision]);

  return (
    <ListResource
      resourceName="ratings"
      resourceModelName="Ratings"
      forceRefresh={forceRefresh}
      columns={columns}
      showAction={false}
      withPageHeader={false}
      withUploadButton={false}
      withSearchBar={false}
      withCreateButton={false}
      withCustomCreateButton
      customActionColumn
      customCreateButtonAction={() => {
        setEditReviewDrawer(false);
        setOpen(true);
      }}
      extraQuery={setExtraQuery()}
      onDoubleClickAction={{
        action: (record) => {
          setEditReviewDrawer(false);
          setShowReviewDrawer(true);
          setReviewData(record);
          setOpen(true);
        }
      }}
    >
      <ReviewDrawer
        t={t}
        open={open}
        setOpen={setOpen}
        supervision={supervision}
        showReviewDrawer={showReviewDrawer}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        questionnaireType={questionnaireType}
        setQuestionnaireType={setQuestionnaireType}
        supervisions={[supervision]}
        customInstanceForm={form}
        editReviewDrawer={editReviewDrawer}
        reviewData={reviewData}
      />
    </ListResource>
  );
};

ListRatings.propTypes = {
  owner: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.string
  }).isRequired,
  supervision: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
