import { Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../errorMessage';

/**
 * Custom hook that generates a PDF document of a specified type for a pensioner and initiates its download.
 * @hook
 *
 * @returns {function} A function that generates a PDF document of a specified type for a pensioner and initiates its download.
 */
export const useGenerateDocumentsOld = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * Generates a PDF document of a specified type for a pensioner and initiates its download.
   *
   * @async
   * @param {string} documentType - The type of document to generate.
   * @param {string} recordID - The ID of the supervision for which the document is generated.
   * @param {string} filterID - ID allowing to filter arrays in API process.
   * @param {function} setRefreshData - Function to refresh the data after sending the mail.
   * @param {boolean} [printPdf=true] - Whether to print the PDF or return the Blob object.
   * @returns {Promise<void>} A Promise that resolves once the document is generated and downloaded.
   * @throws {Error} If an error occurs during the generation or download process.
   *
   * @example
   * // Usage example:
   * generateDocumentsOld(dispatchAPI, showMessage, 'someDocumentType', '12345')
   */
  const generateDocumentsOld = async (
    documentType,
    recordID,
    filterID,
    setRefreshData,
    printPdf = true
  ) => {
    let modal;
    if (printPdf) {
      modal = Modal.info({
        title: null,
        content: (
          <Row style={{ flexDirection: 'column' }} gutter={[8, 8]}>
            <Col align="center" justify="center" span={24}>
              <Spin />
            </Col>
            <Col align="center" justify="center" span={24}>
              {t('template-documents.form.document_being_generated')}
            </Col>
          </Row>
        ),
        footer: null,
        maskClosable: false
      });
    }
    try {
      const { headers, data } = await dispatchAPI('POST', {
        url: `template-documents/generate/${documentType}/${recordID}/${filterID}`,
        responseType: 'arraybuffer'
      });

      // Create a Blob object from the ArrayBuffer
      const blob = new Blob([data], { type: 'application/pdf' });

      if (printPdf) {
        // Create a URL from the Blob object
        const url = URL.createObjectURL(blob);

        // Use the URL to create a download link or display the PDF in the browser
        const link = document.createElement('a');
        link.href = url;
        link.download = headers['content-disposition'];
        link.click();

        if (setRefreshData) setRefreshData((prev) => !prev);

        // Clean up the URL object
        return URL.revokeObjectURL(url);
      }
      if (setRefreshData) setRefreshData((prev) => !prev);

      return blob;
    } catch (e) {
      return message(e);
    } finally {
      if (printPdf) modal.destroy();
    }
  };

  return { generateDocumentsOld };
};
