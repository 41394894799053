import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ListResource } from '../../../../../components/ListResource/ListResource';
import { Invoice } from '../../../../../models/Invoices';
import { useColumnsInvoice } from './columns/columnsInvoice';

/**
 * ListInvoices component fetches and displays a list of customer invoices related to a specific supervision.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision to filter invoices.
 * @param {Function} props.setCustomerInvoices - Function to set the customer invoices data.
 *
 * @returns {JSX.Element} The rendered ListInvoices component.
 */
export const ListInvoices = ({ supervisionId, setCustomerInvoices }) => {
  const [enums, setEnums] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const navigate = useNavigate();
  const { columns } = useColumnsInvoice({ refreshData, setRefreshData, enums });

  useEffect(() => {
    (async () => {
      const response = await Invoice.getEnums();
      setEnums(response);
    })();
  }, []);

  return (
    <ListResource
      resourceName="customerinvoices"
      columns={columns}
      extraQuery={`supervision=${supervisionId}`}
      customActionColumn
      withPageHeader={false}
      withCreateButton={false}
      widthSearchBar={false}
      forceRefresh={refreshData}
      setResourceData={setCustomerInvoices}
      onDoubleClickAction={{
        action: (record) => navigate(`/invoices/customers/show/${record?._id}`)
      }}
    />
  );
};

ListInvoices.propTypes = {
  supervisionId: PropTypes.string.isRequired,
  setCustomerInvoices: PropTypes.func.isRequired
};
