import dayjs from 'dayjs';

/**
 * Parses the dates in the given data object recursively.
 * @function
 *
 * @param {Object} data - The data object to parse.
 * @returns {Object} - The data object with parsed dates.
 */
const parseDates = (data) => {
  if (typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Date) {
        // eslint-disable-next-line no-param-reassign
        data[key] = dayjs(data[key]);
      } else if (typeof data[key] === 'object') {
        parseDates(data[key]);
      }
    });
  }
  return data;
};

/**
 * Represents a base model for creating and updating data.
 * @class
 */
export class BaseModel {
  /**
   * Gets the name of the model.
   * @returns {string} The name of the model.
   * @throws {Error} If the method is not implemented in the derived class.
   * @static
   */
  static getModelName() {
    throw Error('Please replace getModelName implementation');
  }

  /**
   * Creates a new instance of the BaseModel class.
   * @param {string} modelName - The name of the model.
   * @param {object} schema - The schema used for data validation.
   * @param {object} data - The data to be assigned to the model instance.
   */
  constructor(modelName, schema, data) {
    /**
     * The name of the model.
     * @type {string}
     */
    this.modelName = modelName;

    let verifiedData = schema?.parse(data) || data;
    verifiedData = parseDates(verifiedData);

    Object.keys(verifiedData).forEach((key) => {
      this[key] = verifiedData[key];
    });
  }

  /**
   * Creates a new instance of the model on the server.
   * @param {object} props - The properties of the model to be created.
   * @returns {Promise} A promise that resolves with the created model instance.
   */
  create(props) {
    return this.dispatchAPI('POST', {
      url: `/${this.modelName}`,
      data: { ...props }
    });
  }

  /**
   * Updates the model instance on the server.
   * @param {object} props - The properties of the model to be updated.
   * @returns {Promise} A promise that resolves when the update is successful.
   */
  update(props) {
    return this.dispatchAPI('PATCH', {
      url: `/${this.modelName}/${this.id}`,
      data: { ...props }
    }).then(() => {
      Object.keys(props).forEach((key) => {
        this[key] = props[key];
      });
    });
  }

  /**
   * Fetches data from an API endpoint using the specified method, URL, body, and parameters.
   * @static
   * @function fetchAPI
   * @param {string} method - The HTTP method to use for the request.
   * @param {string} url - The URL of the API endpoint.
   * @param {Object} body - The request body.
   * @param {Object} params - Additional parameters for the request.
   * @returns {Promise<any>} - A promise that resolves to the response data from the API.
   */
  static async fetchAPI(method = '', url = '', body = {}, { ...params } = {}) {
    try {
      const { data } = await this.dispatchAPI(method, {
        url,
        body,
        params: { ...params }
      });
      return data;
    } catch (error) {
      return this.message(error);
    }
  }
}
