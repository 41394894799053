import { BaseModel } from './BaseModel';

/**
 * Class representing a Supervision.
 * @class
 * @extends BaseModel
 */
export class Supervision extends BaseModel {
  static route = 'supervisions';

  constructor(data) {
    super('Supervision', null, data);
  }

  /**
   * Makes an API call using the specified HTTP method, URL, request body, and parameters.
   * @static
   * @function
   *
   * @param {string} method - The HTTP method to use for the API call (e.g., 'GET', 'POST').
   * @param {string} url - The URL endpoint for the API call.
   * @param {Object} [body] - The request body to send with the API call (for methods like POST or PUT).
   * @param {Object} [params] - Additional parameters to include with the API call.
   * @returns {Promise} - A promise that resolves with the response from the API call.
   */
  static fetchAPI(method, url, body, params) {
    return BaseModel.fetchAPI(method, url, body, params);
  }

  /**
   * Retrieves all supervision records.
   * @static
   * @function
   *
   * @param {Object} [params={}] - Optional parameters to filter the supervision records.
   * @returns {Promise<Object[]>} A promise that resolves to an array of supervision records.
   */
  static getAll(params = {}) {
    return this.fetchAPI('GET', this.route, {}, params);
  }

  /**
   * Retrieves a single supervision record by its ID.
   * @static
   * @function
   *
   * @param {string|number} id - The ID of the supervision record to retrieve.
   * @param {Object} [params={}] - Optional parameters to include in the request.
   * @returns {Promise<Object>} - A promise that resolves to the supervision record.
   */
  static getOne(id, params = {}) {
    return this.fetchAPI('GET', `${this.route}/form/${id}`, {}, params);
  }

  /**
   * Sends a PATCH request to the specified URL with the given body and parameters.
   * @static
   * @function
   *
   * @param {string} url - The endpoint to which the PATCH request is sent.
   * @param {Object} body - The data to be sent in the body of the PATCH request.
   * @param {Object} [params={}] - Optional parameters to include in the request.
   * @returns {Promise} - A promise that resolves with the response of the fetchAPI call.
   */
  static patch(url, body, params = {}) {
    return this.fetchAPI('PATCH', `${this.route}/${url}`, body, params);
  }

  /**
   * Deletes a resource by its ID.
   * @static
   * @function
   *
   * @param {number|string} id - The ID of the resource to delete.
   * @param {Object} [params={}] - Additional parameters to include in the request.
   * @returns {Promise} - A promise that resolves when the resource is deleted.
   */
  static delete(id, params = {}) {
    return this.fetchAPI('DELETE', `${this.route}/${id}`, {}, params);
  }

  /**
   * Retrieves the prices for supervisions.
   * @static
   * @function
   * @param {Object} [params={}] - Optional parameters to filter the supervision prices.
   * @returns {Promise<Object>} A promise that resolves to the supervision prices.
   */
  static getSupervisionsPrices(params = {}) {
    return this.fetchAPI('GET', 'supervisionprices', {}, params);
  }
}
