import { Card, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListRatings } from '../dataTables/ListRatings';

/**
 * Renders a card component that displays ratings for a supervision.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.supervision - The supervision object containing ratings data.
 * @param {boolean} props.isLoading - A flag indicating whether the ratings are currently being loaded.
 * @returns {JSX.Element} The rendered component.
 */
export const RatingsCard = ({ supervision, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card title={t('supervisions.show.opinions')}>
        <ListRatings supervision={supervision} />
      </Card>
    </Spin>
  );
};

RatingsCard.propTypes = {
  supervision: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired
};
