import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useFields } from './fieldsMailingTemplate';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * CreateUpdateTemplateMails component for creating or updating template mails.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the template (create/update).
 * @returns {React.ReactNode} - The rendered component.
 */
export const CreateUpdateTemplateMails = ({ purpose }) => {
  const [content, setContent] = useState('');

  const { fields } = useFields(content, setContent);
  const { t } = useTranslation();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setContent(data.template_content);

        return data;
      }
    }
  };

  return (
    <CreateUpdateContainer
      config={config}
      fields={fields}
      purpose={purpose}
      title={t('template_mails.template_mail_create_edit')}
      baseUrl="templates-mails"
      resource="templates-mails"
      tradKey="template_mails"
    />
  );
};

CreateUpdateTemplateMails.propTypes = {
  purpose: PropTypes.string.isRequired
};
