import dayjs from 'dayjs';

/**
 * Custom hook that returns a configuration object with callback functions for handling resource data.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setPetsittingAddressFileList - The function to set the petsitting address file list.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {Function} options.setSelectedAnouncePhotos - The function to set the selected announce photos.
 * @param {Function} options.setSupervisionAddressData - The function to set the supervision address data.
 * @param {Object} options.startDateRef - The reference object for the start date.
 * @param {Object} options.endDateRef - The reference object for the end date.
 * @param {Object} options.animals - The animals object.
 * @param {Function} options.setReduction - The function to set the reduction.
 * @param {Function} options.setOwner - The function to set the owner.
 * @param {Function} options.setFormDataEnvironment - The function to set the form data environment.
 * @param {String} options.setCountry - The function to set the country.
 * @param {String} options.typeOfDwelling - The type of dwelling.
 * @param {Function} options.setTypeOfDwelling - The function to set the type of dwelling.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  setPetsittingAddressFileList,
  dispatchAPI,
  setSelectedAnouncePhotos,
  setSupervisionAddressData,
  startDateRef,
  endDateRef,
  animals,
  setReduction,
  setOwner,
  setEnvironmentType,
  setCountry,
  setTypeOfDwelling
}) => ({
  onGetResource: {
    setFields: async (data) => {
      const { data: veterinarianData } = await dispatchAPI('GET', {
        url: `veterinarians?supervision=${data._id}`
      });
      const startDate = startDateRef;
      const endDate = endDateRef;

      setOwner(data.main_informations.owner);
      setSupervisionAddressData({
        petsitting_address: data.address.petsitting_address
      });

      startDate.current = dayjs(data.main_informations.start_date);
      endDate.current = dayjs(data.main_informations.end_date);

      setTypeOfDwelling(data.address.petsitting_address.type_of_dwelling);
      setReduction(data.summary.reduction);

      if (data.address.petsitting_address.dwelling_photos.length) {
        setPetsittingAddressFileList(
          data.address.petsitting_address.dwelling_photos
        );
      }

      if (data.address.petsitting_address.type_of_environment) {
        setEnvironmentType(data.address.petsitting_address.type_of_environment);
      }

      if (data.summary.anounce_photos.length) {
        setSelectedAnouncePhotos(data.summary.anounce_photos);
      }

      if (data.main_informations.animals.length) {
        const animalsRef = animals;
        animalsRef.current = data.main_informations.animals;
      }

      const newData = {
        main_informations: {
          ...data.main_informations.owner,
          owner: data?.main_informations?.owner?._id,
          accept: data.main_informations.accept,
          owner_name: data?.main_informations?.owner?._id,
          animals: data.main_informations.animals.map((animal) => animal._id),
          accept_smoker: data.main_informations.accept_smoker,
          start_date: dayjs(data.main_informations.start_date),
          end_date: dayjs(data.main_informations.end_date),
          veterinarian: veterinarianData[0]
        },
        petsitting_address: data.address.petsitting_address,
        summary: {
          ...data.summary,
          price_category: {
            duration: data.summary.price_category.duration._id
          }
        }
      };

      setCountry(data?.address?.petsitting_address?.address?.country);

      return newData;
    }
  }
});
