import { Button, Flex, Typography, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useSummaryFields } from '../../fields/summaryFields';
import { useUrgencyFields } from '../../fields/urgencyFields';
import { addTotalTTC } from '../../utils/addTotalTTC';
import { AnouncePhotoGallery } from '../../fields/AnouncePhotoGallery';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Title } = Typography;

/**
 * SummaryForm component renders a form for summarizing supervision details.
 * @component
 *
 * @param {Object} props - The properties object.
 * @param {Function} props.handleNextStep - Function to handle the next step in the form.
 * @param {Function} props.handlePrevious - Function to handle the previous step in the form.
 * @param {Object} props.form - The form object.
 * @param {Function} props.setDuration - Function to set the duration.
 * @param {number} props.duration - The duration value.
 * @param {number} props.differenceDays - The difference in days.
 * @param {Array} props.animalsFileList - List of animal files.
 * @param {Array} props.selectedAnouncePhotos - List of selected announcement photos.
 * @param {Function} props.setSelectedAnouncePhotos - Function to set selected announcement photos.
 * @param {number} props.reduction - The reduction value.
 * @param {Function} props.setReduction - Function to set the reduction.
 * @param {string} props.userRole - The role of the user.
 * @param {string} props.purpose - The purpose of the supervision.
 * @returns {JSX.Element} The rendered SummaryForm component.
 */
export const SummaryForm = ({
  handleNextStep,
  handlePrevious,
  form,
  setDuration,
  duration,
  differenceDays,
  animalsFileList,
  selectedAnouncePhotos,
  setSelectedAnouncePhotos,
  reduction,
  setReduction,
  purpose
}) => {
  const generateFields = useGenerateFormItem();
  const {
    user: { role: userRole }
  } = useAuthContext();
  const {
    petsittingAddressFileList,
    postSupervision,
    supervisionsPrices,
    supervisionPrice,
    setSupervisionPrice,
    summaryData
  } = useSupervisionContext();
  const { t } = useTranslation();
  const { summaryFields } = useSummaryFields({
    supervisionsPrices,
    setDuration,
    duration,
    setReduction,
    userRole
  });
  const { urgencyFields } = useUrgencyFields({});
  const { id } = useParams();

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    addTotalTTC({
      duration,
      reduction,
      form,
      differenceDays,
      supervisionsPrices,
      supervisionPrice,
      setSupervisionPrice
    });
  }, [duration, reduction]);

  useEffect(() => {
    const photosData = animalsFileList.concat(petsittingAddressFileList);
    setPhotos(photosData);

    return () => {
      setPhotos([]);
      setSelectedAnouncePhotos([]);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (summaryData?.summary) await postSupervision(purpose, id);
    })();
  }, [summaryData]);

  return (
    <>
      <Input.Group name={['summary']}>
        {summaryFields.map((field) => generateFields('supervisions', field))}
        <Title level={4}>{t('supervisions.form.urgency_title')}</Title>
        {urgencyFields.map((field) => generateFields('supervisions', field))}
      </Input.Group>
      <Title level={4}>{t('supervisions.form.anounce_photos_title')}</Title>
      <AnouncePhotoGallery
        photos={photos}
        selectedAnouncePhotos={selectedAnouncePhotos}
        setSelectedAnouncePhotos={setSelectedAnouncePhotos}
      />
      <Flex justify="flex-end" gap="small">
        <Button type="link" onClick={handlePrevious}>
          {t('buttons.previous')}
          <ArrowLeftOutlined />
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            await handleNextStep('SUMMARY_DATA');
          }}
        >
          {t('buttons.validate')}
        </Button>
      </Flex>
    </>
  );
};

SummaryForm.propTypes = {
  purpose: PropTypes.string.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  setDuration: PropTypes.func.isRequired,
  duration: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  differenceDays: PropTypes.number.isRequired,
  animalsFileList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAnouncePhotos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedAnouncePhotos: PropTypes.func.isRequired,
  reduction: PropTypes.number,
  setReduction: PropTypes.func.isRequired
};

SummaryForm.defaultProps = {
  reduction: 0
};
