import { BaseModel } from './BaseModel';
/**
 * Owner model
 * @class Owner
 * @extends BaseModel
 * @type {Owner}
 */
export class Owner extends BaseModel {
  constructor(data = {}) {
    super('Owner', null, data);
  }

  /**
   * Makes an API request using the specified HTTP method, URL, request body, and parameters.
   * @static
   * @function
   *
   * @param {string} method - The HTTP method to use for the request (e.g., 'GET', 'POST').
   * @param {string} url - The URL to which the request is sent.
   * @param {Object} [body] - The request body to be sent with the request (optional).
   * @param {Object} [params] - Additional parameters to be included in the request (optional).
   * @returns {Promise} - A promise that resolves with the response of the API request.
   */
  static fetchAPI(method, url, body, params) {
    return BaseModel.fetchAPI(method, url, body, params);
  }

  /**
   * Retrieves all owners based on the provided parameters.
   * @static
   * @function
   * @param {Object} [params={}] - The parameters to filter the owners.
   * @returns {Promise<Object[]>} A promise that resolves to an array of owner objects.
   */
  static getAll(params = {}) {
    return this.fetchAPI('GET', 'owners', {}, params);
  }

  /**
   * Retrieves a single owner by ID.
   * @static
   * @function
   * @param {number|string} id - The ID of the owner to retrieve.
   * @param {Object} [params={}] - Additional query parameters to include in the request.
   * @returns {Promise<Object>} A promise that resolves to the owner data.
   */
  static getOne(id, params = {}) {
    return this.fetchAPI('GET', `owners/${id}`, {}, params);
  }
}
