import {
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InformationMenu } from '../InformationMenu';
import { ContractMenu } from '../ContractMenu';
import { useGenerateDocumentsOld } from '../../../../utils/filesManagement/useGenerateDocumentOld';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';

/**
 * BookedButtons component renders a set of buttons for various actions related to supervisions.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {Function} props.setIsEmailModalOpen - Function to set the state of the email modal.
 * @param {Function} props.setRefreshData - Function to refresh data.
 * @param {Function} props.setCancelSupervisionModalVisible - Function to set the visibility of the cancel supervision modal.
 * @returns {JSX.Element} The rendered component.
 */
export const BookedButtons = ({
  supervisionId,
  setIsEmailModalOpen,
  setRefreshData,
  setCancelSupervisionModalVisible
}) => {
  const { t } = useTranslation();
  const { generateDocumentsOld } = useGenerateDocumentsOld();
  const {
    state: { supervision } = {},
    setRecipients,
    setTemplateType,
    setDocumentType,
    setFilterID,
    setRecordID
  } = useSupervisionContext();

  const { owner, main_informations } = supervision || {};

  return (
    <>
      <Link
        to={{
          pathname: '/invoices/customers/create',
          search: `?ownerId=${owner}&supervisionId=${supervisionId}&ownerUserId=${main_informations.owner.user._id}`
        }}
      >
        <Button type="primary">
          <PlusOutlined />
          {`${t('supervisions.show.buttons.create_invoice')} `}
        </Button>
      </Link>
      <Dropdown
        overlay={
          <ContractMenu
            supervision={supervision}
            setRecipients={setRecipients}
            setIsEmailModalOpen={setIsEmailModalOpen}
            setTemplateType={setTemplateType}
            setDocumentType={setDocumentType}
            setFilterID={setFilterID}
            setRecordID={setRecordID}
            setRefreshData={setRefreshData}
          />
        }
      >
        <Button type="primary">
          {`${t('supervisions.show.buttons.send_contracts')} `}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Dropdown
        overlay={
          <InformationMenu
            supervision={supervision}
            setRecipients={setRecipients}
            setIsEmailModalOpen={setIsEmailModalOpen}
            setTemplateType={setTemplateType}
            setDocumentType={setDocumentType}
            setFilterID={setFilterID}
            setRecordID={setRecordID}
            setRefreshData={setRefreshData}
          />
        }
      >
        <Button type="primary">
          {`${t('supervisions.show.buttons.send_informations')} `}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Button
        type="primary"
        onClick={() =>
          generateDocumentsOld('SUPERVISION_SUMMARY', supervisionId, undefined)
        }
      >
        <UploadOutlined />
        {`${t('supervisions.show.buttons.generate_synthesis')} `}
      </Button>
      <Button
        type="primary"
        style={{ backgroundColor: 'red' }}
        onClick={() => setCancelSupervisionModalVisible(true)}
      >
        <CloseOutlined />
        {`${t('supervisions.show.buttons.cancel_supervision')} `}
      </Button>
    </>
  );
};

BookedButtons.propTypes = {
  supervision: PropTypes.shape({}).isRequired,
  supervisionId: PropTypes.string.isRequired,
  setIsEmailModalOpen: PropTypes.func.isRequired,
  // setRecipients: PropTypes.func.isRequired,
  // setTemplateType: PropTypes.func.isRequired,
  // setDocumentType: PropTypes.func.isRequired,
  // setFilterID: PropTypes.func.isRequired,
  // setRecordID: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  setCancelSupervisionModalVisible: PropTypes.func.isRequired
};
