import { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * React component for the login form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.switchForm - Function to switch between login forms.
 * @returns {JSX.Element} React component.
 */
const LoginForm = ({ switchForm }) => {
  /**
   * React hook for authentication context.
   * @type {Object}
   * @property {boolean} isValid - Flag indicating whether the user is authenticated.
   * @property {Function} dispatchAPI - Function to dispatch authentication API requests.
   * @property {boolean} remember - Flag indicating whether to remember user credentials.
   */
  const { isValid, dispatchAPI, remember } = useAuthContext();

  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  /**
   * Function to handle user login.
   *
   * @async
   * @function
   * @param {string} email - User's email for login.
   * @param {string} password - User's password for login.
   * @param {boolean} rememberMe - Flag indicating whether to remember user credentials.
   * @returns {Promise<void>} Promise representing the login process.
   */
  const loginUser = async (email, password, rememberMe) => {
    setLoading(true);
    try {
      await dispatchAPI('LOGIN', {
        email,
        password,
        rememberMe
      });
      message.success(t('login.success'));
    } catch (e) {
      if (e.response) {
        if (
          e.response.data &&
          e.response.data.description &&
          e.response.data.status_code === 401
        ) {
          message.error(t(`login.${e.response.data.description}`));
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  /**
   * Handler for form submission.
   *
   * @async
   * @function
   * @param {Object} values - Form values.
   * @param {string} values.username - User's username for login.
   * @param {string} values.password - User's password for login.
   * @param {boolean} values.remember - Flag indicating whether to remember user credentials.
   * @returns {Promise<void>} Promise representing the form submission process.
   */
  const handleSubmit = async (values) => {
    await loginUser(
      values.username.toLowerCase(),
      values.password,
      values.remember
    );
  };

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{ remember }}
      layout="vertical"
    >
      {isValid && <Navigate to="/" />}
      <Form.Item
        name="username"
        label={t('login.identifier')}
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('login.password')}
        rules={[{ required: true, message: t('login.pwdMissing') }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item style={{ textAlign: 'left' }}>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>{t('login.rememberMe')}</Checkbox>
        </Form.Item>
        <Button
          type="link"
          style={{ float: 'right', padding: 0, height: 'auto' }}
          onClick={() => switchForm('forgotPwd')}
        >
          {t('login.forgotPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.connect')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          style={{ width: '100%' }}
          onClick={() => switchForm('register')}
        >
          {t('login.register')}
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default LoginForm;
