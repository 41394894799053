import { Route, Routes } from 'react-router-dom';
import { ListMySupervisions } from './ListMySupervisions';
import { ShowMySupervision } from './ShowMySupervision';
import { CreateUpdateSupervision } from '../../supervisions/CreateUpdateSupervision';
import { SupervisionContextProvider } from '../../../contexts/SupervisionContext/SupervisionContext';

export const SupervisionsRouter = () => (
  <SupervisionContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateSupervision purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateSupervision purpose="edit" />}
      />
      <Route path="/details/:id" element={<ShowMySupervision />} />
      <Route index element={<ListMySupervisions />} />
    </Routes>
  </SupervisionContextProvider>
);
