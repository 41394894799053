import JoditEditor from 'jodit-react';
import { useRef } from 'react';

/**
 * useFields hook for defining form fields for template creation or update.
 * @hook
 *
 * @param {string} content - The content of the template.
 * @param {Function} setContent - A function to set the content of the template.
 *
 * @returns {Object} - An object containing the form fields configuration.
 *
 * @property {Array} fields - An array of field objects with name, rules, and optional input component.
 * @property {Array} fields.name - The name of the field (array for nested fields).
 * @property {Array} fields.rules - An array of validation rules for the field.
 * @property {ReactNode} fields.input - An optional React component for custom input (e.g., ReactQuill).
 */
export const useFields = (content, setContent) => {
  const editorRef = useRef(null);

  const fields = [
    {
      name: ['template_name'],
      rules: [{ required: true }]
    },
    {
      name: ['object'],
      rules: [{ required: true }]
    },
    {
      name: ['type'],
      rules: [{ required: true }]
    },
    {
      name: ['template_content'],
      rules: [{ required: true }],
      input: (
        <JoditEditor
          ref={editorRef}
          value={content}
          onChange={setContent}
          style={{ height: '300px', marginBottom: '20px', minWidth: 640 }}
        />
      )
    }
  ];
  return {
    fields
  };
};
