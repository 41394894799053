import { Divider, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import {
  ContainerOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { InvoiceStatus } from '../../../../../../utils/constants/tagColors';
import { deleteInvoice } from '../../../../../invoices/utils/deleteInvoice';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';

/**
 * Custom hook to generate columns configuration for the invoice data table.
 * @hook
 *
 * @param {Object} params - Parameters for the hook.
 * @param {Object} params.enums - Enumeration values for roles and other constants.
 * @param {Function} params.setRefreshData - Function to trigger data refresh.
 * @param {boolean} params.refreshData - Flag indicating whether to refresh data.
 * @returns {Object} - Returns an object containing the columns configuration.
 */
export const useColumnsInvoice = ({ enums, setRefreshData, refreshData }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const columns = [
    {
      title: t('customerinvoices.form.reference'),
      key: 'reference',
      dataIndex: 'reference'
    },
    {
      title: t('customerinvoices.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) =>
        type ? (
          <Tag color={InvoiceStatus[type]}>
            {t(`customerinvoices.tags.${type}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true
    },
    {
      title: t('customerinvoices.form.emission_date'),
      key: 'emission_date',
      dataIndex: 'emission_date',
      sorter: true,
      render: (emission_date) => dayjs(emission_date).format('DD/MM/YYYY')
    },
    {
      title: t('customerinvoices.form.total_ttc'),
      key: 'total_ttc',
      render: (record) =>
        record?.total_ttc
          ? `${record?.total_ttc} ${record?.franc ? 'Fr' : '€'}`
          : '0 €',
      sorter: true
    },
    {
      title: t('customerinvoices.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={InvoiceStatus[status]}>
            {t(`customerinvoices.tags.${status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.roles?.map((r) => ({
        text: t(`customerinvoices.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('customerinvoices.form.payment_amount'),
      key: 'payment_amount',
      render: (record) => {
        if (record?.payment_amount) {
          return `${record.payment_amount} ${record.franc ? 'Fr' : '€'}`;
        }

        return record.franc ? '0 Fr' : '0 €';
      },
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/invoices/customers/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {!['PAID'].includes(record?.status) && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() =>
                  deleteInvoice({
                    dispatchAPI,
                    id: record._id,
                    message,
                    setRefreshData,
                    refreshData,
                    path: 'customerinvoices'
                  })
                }
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return {
    columns
  };
};
