import React from 'react';
import { Menu, Typography, Col, Row } from 'antd';
import { MailOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';
import { generateFilterAndRecordID } from '../utils/generateFilterAndRecordID';

const { Text } = Typography;

/**
 * Renders a menu component for selecting satisfaction options.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.supervision - The supervision object.
 * @param {Function} props.setRecipients - The function to set the recipients.
 * @param {Function} props.setIsEmailModalOpen - The function to set the email modal open state.
 * @param {Function} props.setTemplateType - The function to set the template type.
 * @param {Function} props.setDocumentType - The function to set the document type.
 * @param {Function} props.setFilterID - The function to set the filter ID.
 * @param {Function} props.setRecordID - The function to set the record ID.
 * @returns {JSX.Element} The rendered SatisfactionMenu component.
 */
export const SatisfactionMenu = ({
  supervision,
  setRecipients,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType,
  setFilterID,
  setRecordID,
  setRefreshData
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const options = [];

  options.push({
    ...supervision.main_informations.owner,
    type: 'owner',
    user_collection: 'owners',
    document_type: 'OWNER_SATISFACTION',
    customer_type: 'OWNER'
  });
  supervision.pensioners.forEach((pensioner, index) => {
    options.push({
      ...pensioner,
      type: 'pensioner',
      user_collection: 'pensioners',
      document_type: 'PENSIONER_SATISFACTION',
      customer_type: 'PENSIONER',
      number: index + 1
    });
  });
  return (
    <Menu>
      {options.map((option) => (
        <Menu.Item
          key={option._id || option.type}
          onClick={() => {
            setRecipients([option]);
            setIsEmailModalOpen(true);
            setDocumentType(option.document_type);
            setTemplateType('SEND_SATISFACTION');
            generateFilterAndRecordID({
              dispatchAPI,
              documentType: option.document_type,
              id,
              option,
              supervision,
              setFilterID,
              setRecordID,
              setDocumentType
            });
          }}
        >
          <Row gutter={10}>
            <Col>
              <MailOutlined />
            </Col>
            <Col>
              <Text>
                {t(`supervisions.show.buttons.${option.type}`, {
                  number: option.number
                })}
              </Text>
            </Col>
          </Row>
        </Menu.Item>
      ))}
      {options.map((option) => (
        <Menu.Item
          key={option._id ? `dl${option._id}` : `dl${option.type}`}
          onClick={() =>
            generateDocumentsOld(
              dispatchAPI,
              message,
              option.document_type,
              id,
              option._id,
              t,
              setRefreshData
            )
          }
        >
          <Row gutter={10}>
            <Col>
              <DownloadOutlined />
            </Col>
            <Col>
              <Text>
                {t(`supervisions.show.buttons.${option.type}`, {
                  number: option.number
                })}
              </Text>
            </Col>
          </Row>
        </Menu.Item>
      ))}
      {/* <Menu.Item>
        <DownloadOutlined />
        <TemplateDocumentButton
          parameters={[
            {
              collection: 'Owner',
              documentId: supervision.main_informations.owner._id
            },
            {
              collection: 'Supervision',
              documentId: id
            }
          ]}
          button_text="fiche owner"
          icon={null}
          buttonType="link"
          use_case="OWNER_SATISFACTION"
        />
      </Menu.Item> */}
    </Menu>
  );
};

SatisfactionMenu.propTypes = {
  supervision: PropTypes.shape({
    main_informations: PropTypes.shape({
      owner: PropTypes.shape({
        _id: PropTypes.string
      })
    }),
    pensioners: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string
      })
    )
  }),
  setRecipients: PropTypes.func,
  setIsEmailModalOpen: PropTypes.func,
  setTemplateType: PropTypes.func,
  setDocumentType: PropTypes.func,
  setFilterID: PropTypes.func,
  setRecordID: PropTypes.func,
  setRefreshData: PropTypes.func
};

SatisfactionMenu.defaultProps = {
  supervision: {},
  setRecipients: () => {},
  setIsEmailModalOpen: () => {},
  setTemplateType: () => {},
  setDocumentType: () => {},
  setFilterID: () => {},
  setRecordID: () => {},
  setRefreshData: () => {}
};
