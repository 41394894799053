import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Skeleton,
  Row,
  Card,
  Col,
  List,
  Button,
  Upload,
  notification,
  Space,
  Select
} from 'antd';
import {
  DownloadOutlined,
  CheckCircleOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useDocumentsManagement } from '../../utils/documentsManagement';

const { Dragger } = Upload;

const DocumentsInfoCards = ({
  t,
  isLoading,
  pensioner,
  setRefreshData,
  refreshData,
  setIsLoading
}) => {
  const { downloadDocument } = useDownloadDocument();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [filesList, setFilesList] = useState([]);
  const { selectOptions } = useDocumentsManagement(pensioner, t);

  const translateDocumentType = {
    PENSIONER_CONTRACT_1: t(
      'supervisions.show.documents.PENSIONER_CONTRACT_1',
      { reference: pensioner?.reference }
    ),
    PENSIONER_CONTRACT_2: t(
      'supervisions.show.documents.PENSIONER_CONTRACT_2',
      { reference: pensioner?.reference }
    )
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();

    if (filesList.length) {
      filesList.forEach((file) => {
        if (file.newFile.status === 'error') {
          return notification.warning({
            message: t('supervisions.show.documents.message')
          });
        }

        return formData.append(
          file.newFile.documentType,
          file.newFile.originFileObj
        );
      });

      formData.append('values', JSON.stringify({ user: pensioner?.user?._id }));

      if (filesList.every((file) => file.newFile.status === 'done')) {
        try {
          await dispatchAPI('PATCH', {
            url: `/pensioners/documents/${pensioner._id}`,
            body: formData
          });

          setRefreshData(!refreshData);
          setIsLoading(false);
        } catch (error) {
          message(error);
        }
      }
    }
    setFilesList([]);
  };

  const handleSelectChange = (value, file) => {
    const newFile = file;
    newFile.documentType = value;
    newFile.status = 'done';
    const findIndex = filesList.findIndex((f) => f.newFile.uid === newFile.uid);
    if (findIndex !== -1) {
      const updatedFileList = [...filesList];
      updatedFileList[findIndex] = { newFile };
      setFilesList(updatedFileList);
    } else {
      setFilesList((prevFileList) => [
        ...prevFileList,
        {
          newFile
        }
      ]);
    }
  };

  const draggerProps = () => ({
    onRemove: (file) => {
      setFilesList(filesList.filter((f) => f.uid !== file.uid));
    },
    beforeUpload: (file) => {
      const newFile = file;
      newFile.status = 'error';
      setFilesList((prevFileList) => [
        {
          ...prevFileList,
          newFile
        }
      ]);

      return false;
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    itemRender: (originNode, file) => (
      <Row>
        <Col span={12}>
          <div>{originNode}</div>
        </Col>
        <Col span={12}>
          <Select
            onChange={(value) => handleSelectChange(value, file)}
            style={{ height: 22, width: '100%' }}
            options={selectOptions}
          />
        </Col>
      </Row>
    ),
    filesList
  });

  return (
    <Card title={t('pensioners.show.document_info')} className="file-manager">
      <Row gutter={[24, 24]}>
        <Col span={12} style={{ height: '200px', overflowY: 'auto' }}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            {Object.keys(pensioner).length && pensioner.documents.length ? (
              <List
                className="demo-loadmore-list fileList"
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={pensioner.documents}
                renderItem={(item) => (
                  <List.Item
                    className="fileList-item"
                    actions={[
                      <Button
                        onClick={() =>
                          downloadDocument({
                            _id: item._id._id,
                            metadata: item._id.metadata,
                            contentType: item._id.contentType
                          })
                        }
                        type="link"
                      >
                        <DownloadOutlined style={{ fontSize: 18 }} />
                      </Button>
                    ]}
                  >
                    <Skeleton
                      avatar
                      title={false}
                      loading={item.loading}
                      active
                    >
                      <List.Item.Meta
                        avatar={
                          <CheckCircleOutlined style={{ color: 'green' }} />
                        }
                        description={
                          <Space>
                            <p>{item?._id?.metadata?.originalName}</p>
                            <p>{translateDocumentType[item.type]}</p>
                          </Space>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            ) : null}
          </Skeleton>
        </Col>
        <Col span={12}>
          <Dragger {...draggerProps()}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          <Button onClick={() => handleSubmit()}>{t('buttons.save')}</Button>
        </Col>
      </Row>
    </Card>
  );
};

export default DocumentsInfoCards;

DocumentsInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pensioner: PropTypes.shape({
    _id: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    user: PropTypes.shape({
      _id: PropTypes.string
    }),
    reference: PropTypes.string
  }).isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired
};
