import { Button, Form, Flex, Input, Typography, Upload } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from '../../../owners/form/petsittingAddressForm/fields/fields';
import GeneralInformations from '../../../owners/form/petsittingAddressForm/fields/generalInformationsFields';
import RoomAndLaundryInformations from '../../../owners/form/petsittingAddressForm/fields/roomAndLaundryFields';
import BathroomInformations from '../../../owners/form/petsittingAddressForm/fields/bathroomFields';
import KitchenInformations from '../../../owners/form/petsittingAddressForm/fields/kitchenFields';
import OutsideInformations from '../../../owners/form/petsittingAddressForm/fields/outsideFields';
import ParkingAndInstallationInformations from '../../../owners/form/petsittingAddressForm/fields/parkingAndInstallationFields';
import EquipmentInformations from '../../../owners/form/petsittingAddressForm/fields/equipmentFields';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { draggerProps } from '../../../owners/form/utils/petsittingAddressDraggerProps';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';

const { Title } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

/**
 * SupervisionAddressForm component renders a form for entering supervision address details.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleNextStep - Function to handle the next step in the form.
 * @param {Function} props.handlePrevious - Function to handle the previous step in the form.
 * @param {Object} props.form - The form instance from Ant Design.
 * @param {string} props.country - The selected country.
 * @param {Function} props.setCountry - Function to set the selected country.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const SupervisionAddressForm = ({
  handleNextStep,
  handlePrevious,
  form,
  country,
  setCountry
}) => {
  const generateFields = useGenerateFormItem();
  const {
    petsittingAddressFileList,
    setPetsittingAddressFileList,
    typeOfDwelling,
    setTypeOfDwelling,
    environmentType,
    setEnvironmentType,
    supervisionAddressData
  } = useSupervisionContext();

  const { dispatchAPI, token } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const { formFields } = useFields({
    form,
    country,
    setCountry,
    typeOfDwelling,
    setTypeOfDwelling
  });
  const { generalInformationsFields } = GeneralInformations({
    typeOfDwelling,
    t,
    environmentType,
    setEnvironmentType,
    form
  });
  const { roomAndLaundryInformationsFields } =
    RoomAndLaundryInformations(typeOfDwelling);
  const { bathroomInformationsFields } = BathroomInformations();
  const { kitchenInformationsFields } = KitchenInformations();
  const { outsideInformationsFields } = OutsideInformations();
  const { parkingAndInstallationInformationsFields } =
    ParkingAndInstallationInformations(typeOfDwelling, form);
  const { equipmentInformationsFields } = EquipmentInformations();

  useEffect(() => {
    form.setFieldsValue(supervisionAddressData);
  }, [supervisionAddressData, form]);

  return (
    <>
      <Input.Group
        name={['petsitting_address']}
        className="supervision-address-form"
      >
        <Form.Item noStyle name={['petsitting_address']}>
          {formFields.map((fields) =>
            fields.map((field) => generateFields('petsittingaddress', field))
          )}
        </Form.Item>
        <Title level={4} className="underlined">
          {t('owners.form.general_infos')}
        </Title>
        {generalInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.room_and_laundry')}
        </Title>
        {roomAndLaundryInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.bathroom')}
        </Title>
        {bathroomInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.kitchen')}
        </Title>
        {kitchenInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.outside')}
        </Title>
        {outsideInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.parking')}
        </Title>
        {parkingAndInstallationInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.equipment')}
        </Title>
        {equipmentInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Form.Item
          label={t('owners.form.complementary-infos')}
          name={['petsittingaddress', 'complementary_infos']}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t('owners.form.dwelling-photos')}
          name={['petsittingaddress', 'dwelling_photos']}
        >
          <Dragger
            {...draggerProps(
              t,
              dispatchAPI,
              message,
              petsittingAddressFileList,
              setPetsittingAddressFileList,
              token
            )}
          >
            <p className="ant-upload-drag-icon">
              <CameraOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
        </Form.Item>
      </Input.Group>
      <Flex justify="flex-end" gap="small">
        <Button type="link" danger onClick={handlePrevious}>
          {t('buttons.previous')}
        </Button>
        <Button
          type="add"
          onClick={() => handleNextStep('SUPERVISION_ADDRESS_DATA')}
        >
          {t('buttons.next')}
        </Button>
      </Flex>
    </>
  );
};

SupervisionAddressForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  handlePrevious: PropTypes.func.isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  setFileList: PropTypes.func,
  formDataEnvironment: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFormDataEnvironment: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  setCountry: PropTypes.func.isRequired,
  typeOfDwelling: PropTypes.string,
  setTypeOfDwelling: PropTypes.func
};
SupervisionAddressForm.defaultProps = {
  initialValues: {},
  fileList: [],
  setFileList: () => {},
  typeOfDwelling: '',
  setTypeOfDwelling: () => {}
};
