/**
 * Retrieves a supervision by ID and sets the supervision state.
 * @async
 * @function
 *
 * @param {Object} options - The options for retrieving the supervision.
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API requests.
 * @param {string} options.id - The ID of the supervision to retrieve.
 * @param {Function} options.setSupervision - The function to set the supervision state.
 * @param {Function} options.message - The function to display error messages.
 * @param {Function} options.setIsLoading - The function to set the loading state.
 * @param {Function} options.setAnounce - The function to set the anounce state.
 * @returns {Promise<void>} - A promise that resolves when the supervision is retrieved and set.
 */
export const getSupervision = async ({
  dispatchAPI,
  id,
  setSupervision,
  setVeterinarian,
  message,
  setIsLoading,
  setAnounce
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/supervisions/form/${id}?populate=address.petsitting_address,address.petsitting_address.dwelling_photos,summary.anounce_photos,main_informations.animals,main_informations.animals.animal_photo,pensioners,documents,owner`
    });
    setSupervision(data);

    const { data: veterinarian } = await dispatchAPI('GET', {
      url: `veterinarians?owner=${data?.main_informations.owner?._id}`
    });
    if (setVeterinarian) setVeterinarian(veterinarian[0]);

    if (data) {
      const { data: anounceData } = await dispatchAPI('GET', {
        url: `anounces?supervision=${data?._id}`
      });
      if (setAnounce) setAnounce(anounceData[0]);
    }
    setIsLoading(false);
  } catch (e) {
    message(e);
  }
};
