import {
  Form,
  Input,
  Select,
  Tag,
  DatePicker,
  Upload,
  Button,
  Row,
  Col
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  CloseOutlined
} from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Dragger } = Upload;

export const secondaryProfileInformations = ({
  setFilesList,
  filesList,
  enums,
  isFieldsLoading,
  setRemovedFiles
}) => {
  const { t } = useTranslation();

  const draggerProps = (object, name) => ({
    onRemove: () => {
      const updatedFileList = { ...filesList };

      setRemovedFiles((prevRemovedFiles) => [
        ...prevRemovedFiles,
        updatedFileList[object][name][0].id
      ]);

      if (name === 'secondary_profile_photo') {
        updatedFileList.secondary_profile.secondary_profile_photo = [];
      } else {
        delete updatedFileList[object][name];
      }
      setFilesList(updatedFileList);
    },
    beforeUpload: (file) => {
      const newFile = file;
      if (name === 'secondary_profile_photo') {
        const reader = new FileReader();
        reader.onload = (e) => {
          newFile.url = e.target.result;
        };
        reader.readAsDataURL(file);
      }
      setFilesList((prevFileList) => {
        if (object in prevFileList) {
          return {
            ...prevFileList,
            [object]: {
              ...prevFileList[object],
              [name]: [newFile]
            }
          };
        }
        return {
          ...prevFileList,
          [object]: {
            [name]: [newFile]
          }
        };
      });

      return false;
    },
    fileList: filesList[object] && filesList[object][name]
  });

  const secondaryProfileInformationsFields = [
    {
      name: ['secondary_profile', 'civility'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.civility || []).map((civility) => (
            <Option key={civility} value={civility}>
              <Tag>{t(`pensioners.tags.${civility}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['secondary_profile', 'last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['secondary_profile', 'first_name']
    },
    {
      name: ['secondary_profile', 'birth_date'],
      input: <DatePicker />
    },
    {
      name: ['secondary_profile', 'profession']
    },
    {
      name: ['secondary_profile', 'principal_language']
    },
    {
      name: ['secondary_profile', 'cell_phone_number'],
      label: 'cell_phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['secondary_profile', 'cell_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['secondary_profile', 'cell_phone_number', 'number']}
          >
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'other_languages.label',
      input: (
        <Form.List name={['secondary_profile', 'other_languages']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <Row justify="space-between" key={key}>
                  <Col>
                    <Button onClick={() => remove(name)}>
                      <CloseOutlined />
                    </Button>
                  </Col>

                  <Form.Item noStyle name={[name]}>
                    <Input />
                  </Form.Item>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
              >
                {t('pensioners.form.other_languages.add_button')}
              </Button>
            </>
          )}
        </Form.List>
      )
    },
    {
      label: 'secondary_profile.criminal_record_extract',
      input: (
        <Dragger
          {...draggerProps(
            'secondary_profile',
            'secondary_profile_criminal_record_extract'
          )}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'secondary_profile.identification_photocopy',
      input: (
        <Dragger
          {...draggerProps(
            'secondary_profile',
            'secondary_profile_identification_photocopy'
          )}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'secondary_profile.identification_photo',
      input: (
        <Dragger
          {...draggerProps(
            'secondary_profile',
            'secondary_profile_identification_photo'
          )}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return { secondaryProfileInformationsFields };
};
