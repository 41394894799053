import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import JoditEditor from 'jodit-react';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import { useMailingFields } from './mailingFields';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { getTemplate } from './utils/getTemplate';
import { sendMail } from './utils/sendMail';
import 'react-quill/dist/quill.snow.css';

export const MailingModal = ({
  isModalOpen,
  setIsModalOpen,
  recipients,
  recipient,
  customFormInstance,
  collection,
  templateType,
  collectionData,
  documentType,
  recordID,
  filterID,
  setRefreshData
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const { mailingFields } = useMailingFields({
    recipients,
    isFieldsLoading,
    t,
    collection,
    setFilesList,
    filesList
  });
  const generateFields = useGenerateFormItem();
  const [editorState, setEditorState] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    (async () => {
      await getTemplate({
        dispatchAPI,
        templateType,
        setEditorState,
        customFormInstance,
        message,
        collectionData,
        recipient,
        setIsFieldsLoading,
        t
      });
    })();
  }, []);

  return (
    <Modal
      footer={null}
      title={t('customerinvoices.show.modals.reminder')}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
    >
      <Form
        form={customFormInstance}
        onFinish={(value) =>
          sendMail({
            value,
            dispatchAPI,
            message,
            templateType,
            setIsSubmitting,
            editorState,
            setIsModalOpen,
            documentType,
            recordID,
            filterID,
            filesList,
            t,
            setRefreshData
          })
        }
      >
        {mailingFields &&
          mailingFields.map((field) => generateFields('mailing', field))}
        <Spin spinning={isFieldsLoading}>
          <JoditEditor
            ref={editorRef}
            value={editorState}
            onChange={(value) => setEditorState(value)}
            style={{ height: '300px', marginBottom: '20px', minWidth: 640 }}
          />
        </Spin>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => setIsModalOpen(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.send')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

MailingModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({})),
  recipient: PropTypes.string.isRequired,
  customFormInstance: PropTypes.shape({}),
  collection: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired,
  collectionData: PropTypes.shape({}).isRequired,
  documentType: PropTypes.string,
  recordID: PropTypes.string,
  filterID: PropTypes.string,
  setRefreshData: PropTypes.func.isRequired
};

MailingModal.defaultProps = {
  recipients: [],
  customFormInstance: {},
  documentType: null,
  recordID: null,
  filterID: null
};
