import { CloseOutlined, DownOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InformationMenu } from '../InformationMenu';
import { ContractMenu } from '../ContractMenu';
import { useGenerateDocumentsOld } from '../../../../utils/filesManagement/useGenerateDocumentOld';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';

/**
 * InProgressButtons component renders a set of buttons related to supervision actions.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {Function} props.setIsEmailModalOpen - Function to set the email modal open state.
 * @param {Function} props.setInterruptSupervisionModalVisible - Function to set the interrupt supervision modal visibility.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const InProgressButtons = ({
  supervisionId,
  setIsEmailModalOpen,
  setInterruptSupervisionModalVisible
}) => {
  const { t } = useTranslation();
  const { generateDocumentsOld } = useGenerateDocumentsOld();
  const {
    state: { supervision } = {},
    customerInvoices,
    setRecipients,
    setTemplateType,
    setDocumentType,
    setRecordID,
    setFilterID
  } = useSupervisionContext();

  const { main_informations } = supervision || {};
  return (
    <>
      <Button
        type="primary"
        style={{ backgroundColor: 'red' }}
        onClick={() => {
          const balanceInvoice = customerInvoices.find(
            (invoice) => invoice.type === 'BALANCE'
          );
          setRecipients([
            {
              ...main_informations.owner,
              user_collection: 'owners',
              customer_type: 'OWNER'
            }
          ]);
          setTemplateType('UNPAID_INVOICE_REMINDER');
          setIsEmailModalOpen(true);
          setDocumentType('OWNER-INVOICE');
          setRecordID(balanceInvoice?._id);
        }}
      >
        {`${t('supervisions.show.buttons.call_again_invoice')} `}
      </Button>
      <Dropdown
        overlay={
          <ContractMenu
            supervision={supervision}
            setRecipients={setRecipients}
            setIsEmailModalOpen={setIsEmailModalOpen}
            setTemplateType={setTemplateType}
            setDocumentType={setDocumentType}
            setFilterID={setFilterID}
            setRecordID={setRecordID}
          />
        }
      >
        <Button type="primary">
          {`${t('supervisions.show.buttons.send_contracts')} `}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Dropdown
        overlay={
          <InformationMenu
            supervision={supervision}
            setRecipients={setRecipients}
            setIsEmailModalOpen={setIsEmailModalOpen}
            setTemplateType={setTemplateType}
            setDocumentType={setDocumentType}
            setFilterID={setFilterID}
            setRecordID={setRecordID}
          />
        }
      >
        <Button type="primary">
          {`${t('supervisions.show.buttons.send_informations')} `}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Button
        type="primary"
        onClick={() =>
          generateDocumentsOld('SUPERVISION_SUMMARY', supervisionId, undefined)
        }
      >
        <UploadOutlined />
        {`${t('supervisions.show.buttons.generate_synthesis')} `}
      </Button>
      <Button
        type="primary"
        style={{ backgroundColor: 'red' }}
        onClick={() => setInterruptSupervisionModalVisible(true)}
      >
        <CloseOutlined />
        {`${t('supervisions.show.buttons.interrupt_supervision')} `}
      </Button>
    </>
  );
};

InProgressButtons.propTypes = {
  supervisionId: PropTypes.string.isRequired,
  setIsEmailModalOpen: PropTypes.func.isRequired,
  setInterruptSupervisionModalVisible: PropTypes.func.isRequired
};
