import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { ListResource } from '../../../../../components/ListResource/ListResource';
import ReservationModal from '../../../../reservation/ReservationModal';
import { useColumnsReservations } from './columns/columnsReservations';

/**
 * ListReservations component displays a list of reservations and handles the reservation modal.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {boolean} props.refreshData - Flag to trigger data refresh.
 * @param {Function} props.setRefreshData - Function to set the refresh data flag.
 * @param {Object} props.supervision - The supervision object.
 * @returns {JSX.Element} The rendered component.
 */
export const ListReservations = ({
  supervisionId,
  refreshData,
  setRefreshData,
  supervision
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [pensionerId, setPensionerId] = useState(null);
  const [reservationId, setReservationId] = useState(null);

  const { columns } = useColumnsReservations({
    reservations,
    setIsModalOpen,
    setPensionerId,
    setReservationId
  });

  return (
    <>
      <ListResource
        forceRefresh={refreshData}
        resourceName="reservations"
        columns={columns}
        extraQuery={`supervision=${supervisionId}`}
        populate="supervision,pensioner,supervision.address.petsitting_address"
        customActionColumn
        withPageHeader={false}
        withCreateButton={false}
        setResourceData={setReservations}
      />
      <ReservationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        t={t}
        supervision={supervision}
        reservations={reservations}
        pensionerId={pensionerId}
        reservationId={reservationId}
        message={message}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        dispatchAPI={dispatchAPI}
      />
    </>
  );
};

ListReservations.propTypes = {
  supervisionId: PropTypes.string.isRequired,
  refreshData: PropTypes.bool,
  setRefreshData: PropTypes.func,
  supervision: PropTypes.shape({}).isRequired
};

ListReservations.defaultProps = {
  refreshData: false,
  setRefreshData: () => {}
};
