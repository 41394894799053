import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGenerateDocumentsOld } from '../../../../utils/filesManagement/useGenerateDocumentOld';
import { useActionsButtons } from '../../utils/useActionsButtons';
import { routes } from '../../../../utils/constants/adminRoutes';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';

/**
 * CancelButtons component renders a set of buttons for duplicating supervision,
 * generating a supervision summary document, and archiving a supervision.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {Function} props.setRefreshData - Function to refresh data after an action.
 * @returns {JSX.Element} The rendered CancelButtons component.
 *
 * @example
 * <CancelButtons supervisionId="123" setRefreshData={refreshDataFunction} />
 */
export const CancelButtons = ({ supervisionId, setRefreshData }) => {
  const { t } = useTranslation();
  const { generateDocumentsOld } = useGenerateDocumentsOld();
  const { handleDelete } = useActionsButtons({ supervisionId, setRefreshData });
  const { state: { supervision } = {} } = useSupervisionContext();

  const { owner } = supervision || {};

  return (
    <>
      <Link
        to={{
          pathname: `${routes.SUPERVISIONS}/create`,
          search: `?id=${owner}`
        }}
      >
        <Button type="primary">
          <PlusOutlined />
          {`${t('supervisions.show.buttons.duplicate_supervision')} `}
        </Button>
      </Link>
      <Button
        type="primary"
        onClick={() =>
          generateDocumentsOld('SUPERVISION_SUMMARY', supervisionId, undefined)
        }
      >
        <UploadOutlined />
        {`${t('supervisions.show.buttons.generate_synthesis')} `}
      </Button>
      <Popconfirm
        title={t('datatable.column.action.archive.title')}
        okText={t('datatable.column.action.archive.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.archive.cancel')}
        onConfirm={handleDelete}
        icon={<WarningOutlined />}
      >
        <Button type="link" danger>
          {`${t('buttons.archive')} `}
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </>
  );
};

CancelButtons.propTypes = {
  setRefreshData: PropTypes.func.isRequired,
  supervisionId: PropTypes.string.isRequired
};
