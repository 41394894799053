import React from 'react';
import { Menu, Typography, Col, Row } from 'antd';
import { MailOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { generateContractDocuments } from '../utils/generateContractDocuments';
import { generateFilterAndRecordID } from '../utils/generateFilterAndRecordID';

const { Text } = Typography;

/**
 * ContractMenu component renders a menu with options to send emails and download contract documents
 * for supervision owners and pensioners.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.supervision - The supervision data.
 * @param {Function} props.setRecipients - Function to set the recipients for the email.
 * @param {Function} props.setIsEmailModalOpen - Function to open the email modal.
 * @param {Function} props.setTemplateType - Function to set the email template type.
 * @param {Function} props.setDocumentType - Function to set the document type.
 * @param {Function} props.setFilterID - Function to set the filter ID.
 * @param {Function} props.setRecordID - Function to set the record ID.
 * @param {Function} props.setRefreshData - Function to refresh the data.
 *
 * @returns {JSX.Element} The rendered ContractMenu component.
 */
export const ContractMenu = ({
  supervision,
  setRecipients,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType,
  setFilterID,
  setRecordID,
  setRefreshData
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const options = [];

  options.push({
    ...supervision.main_informations.owner,
    type: 'owner',
    customer_type: 'OWNER',
    collection: 'Supervision',
    user_collection: 'owners'
  });
  supervision.pensioners.forEach((pensioner, index) => {
    options.push({
      ...pensioner,
      type: 'pensioner',
      collection: 'Supervision',
      use_case: 'PENSIONER_SUPERVISION_CONTRACT_SEND',
      customer_type: 'PENSIONER',
      user_collection: 'pensioners',
      number: index + 1
    });
  });
  return (
    <Menu>
      {options.map((option) => {
        const documentsType =
          option.type === 'owner'
            ? [`${option.customer_type}_CONTRACT`, 'OWNER-INVOICE']
            : ['PENSIONER_CONTRACT'];
        const documentType =
          option.type === 'owner' ? 'OWNER_CONTRACT' : 'PENSIONER_CONTRACT';
        return (
          option.email && (
            <Menu.Item
              key={option._id || option.type}
              onClick={() => {
                setRecipients([option]);
                setIsEmailModalOpen(true);
                setTemplateType(`CONTRACT_${option.customer_type}`);
                setDocumentType(documentsType);
                generateFilterAndRecordID({
                  dispatchAPI,
                  documentType,
                  id,
                  option,
                  supervision,
                  setFilterID,
                  setRecordID,
                  setDocumentType
                });
              }}
            >
              <Row gutter={10}>
                <Col>
                  <MailOutlined />
                </Col>
                <Col>
                  <Text>
                    {t(`supervisions.show.buttons.${option.type}`, {
                      number: option.number
                    })}
                  </Text>
                </Col>
              </Row>
            </Menu.Item>
          )
        );
      })}
      {options.map((option) => {
        const documentType =
          option.type === 'owner' ? 'OWNER_CONTRACT' : 'PENSIONER_CONTRACT';
        return (
          <Menu.Item
            key={option._id ? `dl${option._id}` : `dl${option.type}`}
            onClick={() =>
              generateContractDocuments({
                dispatchAPI,
                message,
                documentType,
                id,
                option,
                t,
                supervision,
                setRefreshData
              })
            }
          >
            <Row gutter={10}>
              <Col>
                <DownloadOutlined />
              </Col>
              <Col>
                <Text>
                  {t(`supervisions.show.buttons.${option.type}`, {
                    number: option.number
                  })}
                </Text>
              </Col>
            </Row>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

ContractMenu.propTypes = {
  supervision: PropTypes.shape({
    main_informations: PropTypes.shape({
      owner: PropTypes.shape({
        _id: PropTypes.string
      })
    }),
    pensioners: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string
      })
    )
  }),
  setRecipients: PropTypes.func,
  setIsEmailModalOpen: PropTypes.func,
  setTemplateType: PropTypes.func,
  setDocumentType: PropTypes.func,
  setFilterID: PropTypes.func,
  setRecordID: PropTypes.func,
  setRefreshData: PropTypes.func.isRequired
};

ContractMenu.defaultProps = {
  supervision: {},
  setRecipients: () => {},
  setIsEmailModalOpen: () => {},
  setTemplateType: () => {},
  setDocumentType: () => {},
  setFilterID: () => {},
  setRecordID: () => {}
};
