import { BaseModel } from './BaseModel';

/**
 * Class representing an Invoice.
 * @class
 * @extends BaseModel
 */
export class Invoice extends BaseModel {
  /**
   * The route for customer invoices.
   * @type {string}
   */
  static route = 'customerinvoices';

  /**
   * Create an Invoice.
   * @param {Object} data - The data for the invoice.
   */
  constructor(data) {
    super('Invoice', null, data);
  }

  /**
   * Makes an API call using the specified HTTP method, URL, body, and parameters.
   * @static
   * @function
   *
   * @param {string} method - The HTTP method to use for the API call (e.g., 'GET', 'POST').
   * @param {string} url - The URL endpoint for the API call.
   * @param {Object} [body] - The request payload to send with the API call (optional).
   * @param {Object} [params] - Additional parameters to include in the API call (optional).
   * @returns {Promise} - A promise that resolves with the response of the API call.
   */
  static fetchAPI(method, url, body, params) {
    return BaseModel.dispatchAPI(method, { url, body, params });
  }

  /**
   * Fetches enumeration values from the API.
   * @static
   * @function
   *
   * @returns {Promise<Object>} A promise that resolves to the enumeration values.
   */
  static getEnums() {
    return this.fetchAPI('GET', `${this.route}/enums`);
  }
}
