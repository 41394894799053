import React, { useEffect } from 'react';
import { Button, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useMainInfoFields } from '../../fields/mainInfoFields';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';

/**
 * MainInformationsForm component renders a form for main information input and handles dynamic field setting.
 * @component
 *
 * @param {Function} handleNextStep - Function to handle the transition to the next step.
 * @param {Object} animalsRef - Reference object for animals.
 * @param {Object} form - Form instance for managing form state.
 * @param {Date} startDate - The start date for the supervision.
 * @param {Date} endDate - The end date for the supervision.
 *
 * @returns {JSX.Element} The rendered form component.
 */
export const MainInformationsForm = ({
  handleNextStep,
  animalsRef,
  form,
  startDate,
  endDate
}) => {
  const generateFields = useGenerateFormItem();
  const { dispatchAPI, user } = useAuthContext();
  const navigate = useNavigate();
  const { owner, owners, enums, setOwnerId, isFieldsLoading } =
    useSupervisionContext();
  const { t } = useTranslation();

  const { informationsFields } = useMainInfoFields({
    enums,
    t,
    isFieldsLoading,
    dispatchAPI,
    setOwnerId,
    owners,
    startDate,
    endDate,
    animalsRef,
    owner,
    userRole: user.role
  });

  const setDynamicFields = async () => {
    if (Object.keys(owner).length) {
      const { data } = await dispatchAPI('GET', {
        url: `/veterinarians?owner=${owner._id}`
      });

      form.setFieldsValue({
        main_informations: {
          owner_name: owner._id,
          owner: owner._id,
          veterinarian: data.length ? data[0] : null
        }
      });
    }
  };

  useEffect(() => {
    setDynamicFields();
  }, [owner]);

  return (
    <>
      {informationsFields.map((field) => generateFields('supervisions', field))}
      <Flex justify="flex-end" gap="small">
        <Button type="link" danger onClick={() => navigate(-1)}>
          {t('buttons.cancel')}
        </Button>
        <Button type="add" onClick={() => handleNextStep('INFORMATION_DATA')}>
          {t('buttons.next')}
        </Button>
      </Flex>
    </>
  );
};

MainInformationsForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  enums: PropTypes.shape({}).isRequired,
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(PropTypes.shape({})),
    veterinarian: PropTypes.shape({}),
    _id: PropTypes.string
  }).isRequired,
  animalsRef: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired
  }).isRequired,
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({})
};
MainInformationsForm.defaultProps = {
  initialValues: {},
  startDate: {},
  endDate: {}
};
