import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to generate columns configuration for ratings data table.
 * @hook
 *
 * @param {Object} params - Parameters for the hook.
 * @param {Function} params.setEditReviewDrawer - Function to set the state of the edit review drawer.
 * @param {Function} params.setShowReviewDrawer - Function to set the state of the show review drawer.
 * @param {Function} params.setReviewData - Function to set the review data.
 * @param {Function} params.setOpen - Function to set the open state.
 * @returns {Object} - Returns an object containing the columns configuration.
 */
export const useColumnsRatings = ({
  setEditReviewDrawer,
  setShowReviewDrawer,
  setReviewData,
  setOpen
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('ratings.form.left_by'),
      key: 'author',
      render: (author) =>
        `${author?.id?.last_name || ''} ${author?.id?.first_name || ''}` || '-',
      dataIndex: 'author'
    },
    {
      title: t('ratings.form.received_by'),
      key: 'receiver',
      dataIndex: 'receiver',
      render: (receiver) => {
        if (receiver && receiver.id.length) {
          return receiver.id
            .map(
              (user) =>
                `${
                  user?.civility ? t(`pensioners.tags.${user.civility}`) : ''
                } ${user?.last_name || ''} ${user?.first_name || ''}`
            )
            .join(', ');
        }
        return '-';
      }
    },
    {
      title: t('ratings.form.supervision_city'),
      key: 'supervision',
      dataIndex: 'supervision',
      render: (superv) =>
        superv?.address?.petsitting_address?.address.city || '-'
    },
    {
      title: t('ratings.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => dayjs(date).format('DD/MM/YYYY') || '-'
    },
    {
      title: t('ratings.form.rating'),
      key: 'rating',
      dataIndex: 'rating',
      render: (rating) => rating || '-'
    },
    {
      key: 'action',
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setEditReviewDrawer(false);
              setShowReviewDrawer(true);
              setReviewData(record);
              setOpen(true);
            }}
          >
            <EyeOutlined />
          </Button>
          <Button
            type="link"
            onClick={() => {
              setShowReviewDrawer(false);
              setEditReviewDrawer(true);
              setReviewData(record);
              setOpen(true);
            }}
          >
            <EditOutlined />
          </Button>
        </>
      )
    }
  ];

  return {
    columns
  };
};
