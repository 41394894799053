import {
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
  UploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, notification, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { useGenerateDocumentsOld } from '../../../../utils/filesManagement/useGenerateDocumentOld';
import { editingLocked } from '../../../../utils/editingLocked';
import { routes } from '../../../../utils/constants/userRoutes';
import { useActionsButtons } from '../../utils/useActionsButtons';
import { useSocketContext } from '../../../../contexts/SocketContext';

/**
 * Component rendering a set of buttons for supervision actions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setAnounceModalVisible - Function to set the visibility of the announce modal.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {Function} props.setRefreshData - Function to refresh data.
 * @returns {JSX.Element} The rendered component.
 */
export const WaitingButtons = ({
  setAnounceModalVisible,
  supervisionId,
  setRefreshData
}) => {
  const { t } = useTranslation();
  const { generateDocumentsOld } = useGenerateDocumentsOld();
  const { handleDelete } = useActionsButtons({ supervisionId, setRefreshData });
  const { editSupervisionLock } = useSocketContext();

  return (
    <>
      <Button type="primary" onClick={() => setAnounceModalVisible(true)}>
        <RedoOutlined />
        {`${t('supervisions.show.buttons.create_anounce')} `}
      </Button>
      <Button
        type="primary"
        onClick={() =>
          generateDocumentsOld('SUPERVISION_SUMMARY', supervisionId, undefined)
        }
      >
        <UploadOutlined />
        {`${t('supervisions.show.buttons.generate_synthesis')} `}
      </Button>
      <Link
        to={{
          pathname: !editingLocked(editSupervisionLock, supervisionId)
            ? `${routes.SUPERVISIONS}/edit/${supervisionId}`
            : ''
        }}
      >
        <Button
          type="primary"
          onClick={() =>
            editingLocked(editSupervisionLock, supervisionId) &&
            notification.warning({
              message: t('pensioners.profile_lock'),
              duration: false
            })
          }
        >
          {`${t('buttons.edit')} `}
          <EditOutlined />
        </Button>
      </Link>
      <Popconfirm
        title={t('datatable.column.action.archive.title')}
        okText={t('datatable.column.action.archive.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.archive.cancel')}
        onConfirm={handleDelete}
        icon={<WarningOutlined />}
      >
        <Button type="link" danger>
          {`${t('buttons.archive')} `}
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </>
  );
};

WaitingButtons.propTypes = {
  setAnounceModalVisible: PropTypes.func.isRequired,
  supervisionId: PropTypes.string.isRequired,
  setRefreshData: PropTypes.func.isRequired
};
