import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  UploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SatisfactionMenu } from '../SatisfactionMenu';
import { ContractMenu } from '../ContractMenu';
import { useGenerateDocumentsOld } from '../../../../utils/filesManagement/useGenerateDocumentOld';
import { routes } from '../../../../utils/constants/adminRoutes';
import { useActionsButtons } from '../../utils/useActionsButtons';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';

/**
 * FinishedButtons component renders a set of action buttons related to supervision.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {Function} props.setRefreshData - Function to refresh data.
 * @param {Function} props.setIsEmailModalOpen - Function to set the email modal open state.
 * @returns {JSX.Element} The rendered component.
 */
export const FinishedButtons = ({
  supervisionId,
  setRefreshData,
  setIsEmailModalOpen
}) => {
  const { t } = useTranslation();
  const { generateDocumentsOld } = useGenerateDocumentsOld();
  const { handleDelete } = useActionsButtons({ supervisionId, setRefreshData });
  const {
    state: { supervision } = {},
    setRecipients,
    setTemplateType,
    setDocumentType,
    setRecordID,
    setFilterID,
    customerInvoices
  } = useSupervisionContext();

  const { main_informations, owner } = supervision || {};

  return (
    <>
      <Button
        type="primary"
        style={{ backgroundColor: 'red' }}
        onClick={() => {
          const balanceInvoice = customerInvoices.find(
            (invoice) => invoice.type === 'BALANCE'
          );
          setRecipients([
            {
              ...main_informations.owner,
              user_collection: 'owners',
              customer_type: 'OWNER'
            }
          ]);
          setTemplateType('UNPAID_INVOICE_REMINDER');
          setIsEmailModalOpen(true);
          setDocumentType('OWNER-INVOICE');
          setRecordID(balanceInvoice?._id);
        }}
      >
        {`${t('supervisions.show.buttons.call_again_invoice')} `}
      </Button>
      <Dropdown
        overlay={
          <ContractMenu
            supervision={supervision}
            setRecipients={setRecipients}
            setIsEmailModalOpen={setIsEmailModalOpen}
            setTemplateType={setTemplateType}
            setDocumentType={setDocumentType}
            setFilterID={setFilterID}
            setRecordID={setRecordID}
          />
        }
      >
        <Button type="primary">
          {`${t('supervisions.show.buttons.send_contracts')} `}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Link
        to={{
          pathname: `${routes.SUPERVISIONS}/create`,
          search: `?id=${owner}`
        }}
      >
        <Button type="primary">
          <PlusOutlined />
          {`${t('supervisions.show.buttons.duplicate_supervision')} `}
        </Button>
      </Link>
      <Dropdown
        overlay={
          <SatisfactionMenu
            supervision={supervision}
            setRecipients={setRecipients}
            setIsEmailModalOpen={setIsEmailModalOpen}
            setTemplateType={setTemplateType}
            setDocumentType={setDocumentType}
            setFilterID={setFilterID}
            setRecordID={setRecordID}
            setRefreshData={setRefreshData}
          />
        }
      >
        <Button type="primary">
          {`${t('supervisions.show.buttons.satisfaction')} `}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Button
        type="primary"
        onClick={() =>
          generateDocumentsOld('SUPERVISION_SUMMARY', supervisionId, undefined)
        }
      >
        <UploadOutlined />
        {`${t('supervisions.show.buttons.generate_synthesis')} `}
      </Button>
      <Popconfirm
        title={t('datatable.column.action.archive.title')}
        okText={t('datatable.column.action.archive.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.archive.cancel')}
        onConfirm={handleDelete}
        icon={<WarningOutlined />}
      >
        <Button type="link" danger>
          {`${t('buttons.archive')} `}
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </>
  );
};

FinishedButtons.propTypes = {
  setRefreshData: PropTypes.func.isRequired,
  supervisionId: PropTypes.string.isRequired,
  setIsEmailModalOpen: PropTypes.func.isRequired
};
